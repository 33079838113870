import bannerImg from "../../assets/images/img/banner1.png"
import { DeleteButton, EditButton, PublishButton } from "../button/Button";
const BannerComponent = ({ children, image, title, subtitle, description }) => {
    return (
        <div className='banner-container row pb-5 pb-md-0'>
            <EditButton className="edit-btn" />
            <div className="col-lg-6 p-3">
                <div className="">
                    <img src={bannerImg} className="w-100" alt="" />
                </div>

            </div>
            <div className="col-lg-6 banner-text-container pt-md-5 pb-5">
                <div className="mb-2">
                    <div style={{ color: "black", fontWeight: "500" }} className="fs-4 ">
                        {title}
                    </div>
                    <div className="mb-2 text-primary">
                        {subtitle}
                    </div>
                    <div className="small-text w-75 ">
                        {description}
                    </div>
                </div>
            </div>
            <div className="row button-container col-lg-6">
                <div className="col-md-3 p-md-0 mb-1">
                    <DeleteButton className="col-12 "/>
                </div>
                <div className="col-md-9 p-y">
                    <PublishButton className="col-12" />
                </div>
            </div>
        </div>
    )
}

export default BannerComponent;