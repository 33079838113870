import { useState, useEffect, useRef, useContext } from 'react';
import useAuth from '../hooks/useAuth';
import '../assets/styles/Login.css'
import { BASE_API_URL } from '../shared/global';
import logo_full from "../assets/images/logo-full.png"
import log from "../assets/images/log.png"
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { POST } from '../shared/services/BaseServices';
import Swal from 'sweetalert2';
import axios from '../api/axios';

const LOGIN_URL = '/login/'

export default function Login() {
	const { setAuth } = useAuth();

	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || "/"

	const userRef = useRef();
	const errRef = useRef();

	const [userName, setUserName] = useState('')
	const [passWord, setPassword] = useState('')
	const [erorMsg, setErrorMsg] = useState('');
	const [exist, setExist] = useState(true)

	useEffect(() => {
		localStorage.setItem("userName", '')
		localStorage.setItem("passWord", '')
		localStorage.setItem("accessToken", '')
		localStorage.setItem("role", '')
		localStorage.setItem("institute", '')
		setAuth('')
	}, [])
	useEffect(() =>
		async function () {
			const response = await fetch(`${BASE_API_URL}/company/check/`);
			let resJSON = await response.json();
						console.log(resJSON)
			// console.log(resJSON.data.exist)
			if (resJSON.data.exist == false) {
				setExist(resJSON.data.exist)
			}
		}, []);
	useEffect(() => {
		userRef.current.focus();
	}, []);
	useEffect(() => {
		setErrorMsg('');
	}, [userName, passWord])


	const handleClick = async (e) => {
		e.preventDefault();

		let data = JSON.stringify({
			username: userName,
			password: passWord,
		})

		try {
			const response = await axios.post(LOGIN_URL, data,
				{
					headers: { 'Content-Type': 'application/json' },
					// withCredentials: true
				}
			);
			let res = response
			// console.log(JSON.stringify(response?.data));
			// console.log(JSON.stringify(response));
			const accessToken = response?.data?.data?.token;
			const role = response?.data?.data?.fk_role?.role;
			const institute = response?.data?.data?.fk_role?.fk_institute
			if (res.data.success === true) {
				localStorage.setItem("userName", userName)
				localStorage.setItem("passWord", passWord)
				localStorage.setItem("accessToken", accessToken)
				localStorage.setItem("role", role)
				localStorage.setItem("institute", institute)
				setAuth({ userName, passWord, accessToken, role, institute })
				// console.log(userName, passWord, accessToken)
				setUserName('')
				setPassword('')
				Swal.fire("", res.data.message, 'success')
				navigate(from, { replace: true });
			} else {
				// console.log(res.data)
				Swal.fire("", res.data.message, 'error')
			}

		} catch (err) {
			if (!err?.response) {
				setErrorMsg('No server response');
			}
			else if (err.response?.status === 400) {
				setErrorMsg("Missing username or password");
			}
			else if (erorMsg.response?.status === 401) {
				setErrorMsg("Unauthorized user");
			}
			else {
				setErrorMsg("Login failed")
			}
			errRef.current.focus();
		}


		// try {
		// 	// let res = await fetch(`${BASE_API_URL}/login/`, {
		// 	// 	method:'POST',
		// 	// 	headers: {
		// 	//         'Content-Type': 'application/json'
		// 	//     },
		// 	// 	body: data,
		// 	// });
		// 	let res = await POST(`${BASE_API_URL}/login/`, data)
		// 	let resJSON = await res.json();
		// 	if (res.status == 200) {
		// 		if (resJSON) {
		// 			if (resJSON.success == true) {
		// 				Swal.fire(resJSON.message, "", 'success')
		// 				console.log(resJSON)
		// 				localStorage.setItem('authToken', resJSON.data.token)
		// 			}
		// 			else {
		// 				Swal.fire(resJSON.message, "", 'error')
		// 			}
		// 		}
		// 		else {

		// 			navigate('/')
		// 		}
		// 	}
		// }
		// catch (err) {
		// 	console.log(err)
		// }
	}
	// console.log(exist);
	return (
		<>
			<p ref={errRef} className={erorMsg ? "errmsg" : "offscreen"} aria-live="assertive">{erorMsg}</p>
			<div className="container h-100">
				<div className="row h-100 align-items-center justify-contain-center pt-5">
					<div className="col-xl-12 mt-3 pt-5">
						<div className="card">
							<div className="card-body p-0">
								<div className="row m-0">
									<div className="col-xl-6 col-md-6 sign text-center">
										<div>
											<div className="text-center my-5">
												<a href="index.html"><img width="200" src={logo_full} alt="" /></a>
											</div>
											<img src={log} className="education-img"></img>
										</div>
									</div>
									<div className="col-xl-6 col-md-6">
										<div className="sign-in-your">
											<h4 className="fs-20 font-w800 text-black">Sign in your account</h4>
											<span>Welcome back! Login with your data that you entered<br /> during registration</span>
											{/* <div className="login-social">
										<a href="#" className="btn font-w800 d-block my-4"><i className="fab fa-google me-2 text-primary"></i>Login with Google</a>
										<a href="#" className="btn font-w800 d-block my-4"><i className="fab fa-facebook-f me-2 facebook-log"></i>Login with Facebook</a>
									</div> */}
											<form onSubmit={handleClick}>
												<div className="mb-3 mt-5">
													<label htmlFor='username' className="mb-1"><strong>Username</strong></label>
													<input
														id='username'
														onChange={(e) => setUserName(e.target.value)}
														value={userName}
														type="text"
														ref={userRef}
														autoComplete="off"
														className="form-control"
														placeholder="Username"
														required
													/>
												</div>
												<div className="mb-3">
													<label htmlFor='password' className="mb-1"><strong>Password</strong></label>
													<input
														id='password'
														onChange={(e) => setPassword(e.target.value)}
														value={passWord}
														type="password"
														className="form-control"
														placeholder="Password"
														required
													/>
												</div>
												<div className="row d-flex justify-content-between mt-4 mb-2">
													<div className="mb-3">
														{/* <div className="form-check custom-checkbox ms-1">
													<input type="checkbox" className="form-check-input" id="basic_checkbox_1"/>
													<label className="form-check-label" for="basic_checkbox_1">Remember my preference</label>
												</div> */}
													</div>
													<div className="mb-3">
														<Link to="">Forgot Password?</Link>
													</div>
													<div className="mb-3">
														{!exist && <Link to="/register">Create Account</Link>}
													</div>
												</div>
												<div className="text-center">
													<button type="submit" className="btn btn-primary btn-block">Sign Me In</button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}