import React, { useContext, useState, useEffect } from 'react'
import { Link, Navigate } from 'react-router-dom'
import Swal from 'sweetalert2';
import { BASE_API_URL } from '../../shared/global';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import NavTitleContext from '../../context/NavTitleProvider';

export default function InstituteList(props) {
  const [institutes, setInstitutes] = useState('')
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [pincode, setPincode] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [userName, setUserName] = useState('')
  const [password, setPassWord] = useState('');
  const [adminMobile, setAdminMobile] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [edit, setEdit] = useState(false)
  const [instituteId, setInstituteId] = useState()

  const { auth } = useAuth()
  const { setNavbarText } = useContext(NavTitleContext)
  useEffect(() => {
    setNavbarText("Assets")
  }, [])

  const axiosPrivate = useAxiosPrivate();

  // let isMounted = true;
  async function getInstitutes() {
    try {
      const response = await axiosPrivate.get('/institutes/');
      console.log(response);
      setInstitutes(response.data.data)
    } catch (err) {
      console.log(err);
    }
  }
  const SetEdit = (props) => {
    setName(props.institute_name ? props.institute_name : "")
    setAddress(props.address ? props.address : "")
    setPincode(props.pincode ? props.pincode : "")
    setPhone(props.mobile ? props.mobile : "")
    setEmail(props.email ? props.email : "")
    setCode(props.code ? props.code : "")
    setUserName(props.username ? props.username : "")

  }
  useEffect(() => {
    if (edit === true) {
      let institute = institutes.filter(function (data) {
        return data.id === instituteId && data
      })
      // console.log(institute[0])
      // SetEdit(institute[0])

    }

  }, [edit, instituteId])

  useEffect(() => {

    getInstitutes();

    // return () => {
    //   isMounted = false;
    // }
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();

    let data = JSON.stringify({
      institute_name: name,
      address: address,
      pincode: pincode,
      mobile: phone,
      email: email,
      code: code,
      username: userName,
      password: password,
      admin_mobile: adminMobile,
      admin_email: adminEmail,

    });
    try {
      const response = await axiosPrivate.post('/institutes/', data)
      // console.log("Response")
      console.log(response)

      if (response.status == 200) {
        if (response.data.success) {
          Swal.fire("Hey, Good job !!", "Institute Created !!", "success")
          // document.querySelector('[data-bs-dismiss="modal"]').addEventListener('click', function () {
          //   document.querySelector('[data-bs-dismiss="modal"]').toggleAttribute('data-bs-dismiss', 'modal');
          // });
          document.getElementById("close").click();
          setInstitutes('');
          setAddress('');
          setPincode('')
          setPhone('');
          setEmail('');
          setCode('');
          setUserName('');
          setPassWord('');
          setAdminEmail('');
          setAdminMobile('')
          setEdit(false);
          getInstitutes();

        }
        else {
          Swal.fire(response.data.errors, response.data.message, "warning")

        }
      } else {
        // alert("Some error occured");
        Swal.fire("Oops...", "Something went wrong !!", "error")
        console.log(response)
      }


    } catch (err) {
      console.log(err)
    }
    // let res = await fetch(`${BASE_API_URL}`, {
    //   method: 'POST',
    //   body: data,
    // });
    // let resJSON = await res.json();
    // if (res.status == 200) {
    //   if (res.success) {
    //     Swal.fire("Hey, Good job !!", "You clicked the button !!", "success")
    //     document.querySelector('[data-bs-dismiss="modal"]').addEventListener('click', function() {
    //       document.querySelector('[data-bs-dismiss="modal"]').toggleAttribute('data-bs-dismiss', 'modal');
    //     });

    //   }
    //   else {
    //     Swal.fire("Account already exist !!", "Please try to login !!", "warning")

    //   }
    // } else {
    //   // alert("Some error occured");
    //   Swal.fire("Oops...", "Something went wrong !!", "error")
    //   console.log(res)
    // }
  }

  return (
    auth?.role == "company"
      ?
      <div className="content-body pe-0">
        <div className="ms-5">
          <h2>Institute List</h2>
        </div>

        <div className="container-fluid">
          <div className="row"></div>
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header row">
                <h4 className="card-title">
                  <button type="button" onClick={(e) => setEdit(false)} className="btn btn-primary mb-2 float-end" data-bs-toggle="modal" data-bs-target="#addNewModal">
                    Add New
                  </button>
                </h4>
                <div className="modal fade" id="addNewModal">
                  <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h3 className="modal-title">Add Institute</h3>
                        <button type="button" className="btn-close" data-bs-dismiss="modal">
                        </button>
                      </div>
                      <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                          <div className='my-3'>
                            <label htmlFor="">Institute Name</label>
                            <div className=''><input type="text" onChange={(event) => setName(event.target.value)} value={name} className='form-control' name="" id="" /></div>
                          </div>
                          <div className='my-3'>
                            <label htmlFor="">Address</label>
                            <div className=''><textarea onChange={(event) => setAddress(event.target.value)} value={address} className='form-control' name="" id="" ></textarea></div>
                          </div>
                          <div className='my-3'>
                            <label htmlFor="">Pincode</label>
                            <div className=''><input type="number" onChange={(event) => setPincode(event.target.value)} value={pincode} className='form-control' name="" id="" /></div>
                          </div>
                          <div className='my-3'>
                            <label htmlFor="">Phone</label>
                            <div className=''><input type="number" onChange={(event) => setPhone(event.target.value)} value={phone} className='form-control' name="" id="" /></div>
                          </div>
                          <div className='my-3'>
                            <label htmlFor="">Email</label>
                            <div className=''><input onChange={(event) => setEmail(event.target.value)} value={email} type="text" className='form-control' name="" id="" /></div>
                          </div>
                          <div className='my-3'>
                            <label htmlFor="">Institute Code</label>
                            <div className=''><input type="text" onChange={(event) => setCode(event.target.value)} className='form-control' name="" id="" /></div>
                          </div>
                          <div className='mt-4 mb-3 fs-5'>Credentials</div>

                          <div className='my-3'>
                            <label htmlFor="">Username</label>
                            <div className=''><input type="text" value={userName} onChange={(event) => setUserName(event.target.value)} className='form-control' name="" id="" /></div>
                          </div>
                          <div className='my-3'>
                            <label htmlFor="">Password</label>
                            <div className=''><input type="password" value={password} onChange={(event) => setPassWord(event.target.value)} className='form-control' name="" id="" /></div>
                          </div>
                          <div className='my-3'>
                            <label htmlFor="">Admin Email</label>
                            <div className=''><input type="email" value={adminEmail} onChange={(event) => setAdminEmail(event.target.value)} className='form-control' name="" id="" /></div>
                          </div>
                          <div className='my-3'>
                            <label htmlFor="">Admin Mobile</label>
                            <div className=''><input type="tel" value={adminMobile} onChange={(event) => setAdminMobile(event.target.value)} className='form-control' name="" id="" /></div>
                          </div>

                        </div>
                        <div className="modal-footer">
                          <button type="button" id='close' className="btn btn-danger light" data-bs-dismiss="modal">Close</button>
                          <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-responsive-md">
                    <thead>
                      <tr>
                        <th style={{ width: '50px' }}>
                          <div className="form-check custom-checkbox checkbox-success check-lg me-3">
                            <input type="checkbox" className="form-check-input" id="checkAll" required="" />
                            <label className="form-check-label" htmlFor="checkAll"></label>
                          </div>
                        </th>
                        <th><strong>Sl No</strong></th>
                        <th><strong>Institute Name</strong></th>
                        <th><strong>Institute Code</strong></th>
                        <th><strong>Created Date</strong></th>
                        {/* <th><strong>Status</strong></th> */}
                        <th><strong></strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      {institutes && institutes.map((e) => <Institute setInstituteId={setInstituteId} setEdit={setEdit} {...e} />)}
                      {/* <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3">
                          <input type="checkbox" className="form-check-input" id="customCheckBox2" required="" />
                          <label className="form-check-label" htmlFor="customCheckBox2"></label>
                        </div>
                      </td>
                      <td><strong>542</strong></td>
                      <td><div className="d-flex align-items-center"><img src="images/avatar/1.jpg" className="rounded-lg me-2" width="24" alt="" /> <span className="w-space-no">Dr. Jackson</span></div></td>
                      <td>example@example.com	</td>
                      <td>01 August 2020</td>
                      <td>
                        <div className="d-flex">
                          <Link to="#" className="btn btn-primary shadow btn-xs sharp me-1"><i className="fa fa-pencil"></i></Link>
                          <Link to="#" className="btn btn-danger shadow btn-xs sharp"><i className="fa fa-trash"></i></Link>
                        </div>
                      </td>
                    </tr> */}
                      {/* <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3">
                          <input type="checkbox" className="form-check-input" id="customCheckBox3" required="" />
                          <label className="form-check-label" htmlFor="customCheckBox3"></label>
                        </div>
                      </td>
                      <td><strong>542</strong></td>
                      <td><div className="d-flex align-items-center"><img src="images/avatar/2.jpg" className="rounded-lg me-2" width="24" alt="" /> <span className="w-space-no">Dr. Jackson</span></div></td>
                      <td>example@example.com	</td>
                      <td>01 August 2020</td>
                      <td>
                        <div className="d-flex">
                          <Link to="#" className="btn btn-primary shadow btn-xs sharp me-1"><i className="fa fa-pencil"></i></Link>
                          <Link to="#" className="btn btn-danger shadow btn-xs sharp"><i className="fa fa-trash"></i></Link>
                        </div>
                      </td>
                    </tr> */}
                      {/* <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3">
                          <input type="checkbox" className="form-check-input" id="customCheckBox4" required="" />
                          <label className="form-check-label" htmlFor="customCheckBox4"></label>
                        </div>
                      </td>
                      <td><strong>542</strong></td>
                      <td><div className="d-flex align-items-center"><img src="images/avatar/3.jpg" className="rounded-lg me-2" width="24" alt="" /> <span className="w-space-no">Dr. Jackson</span></div></td>
                      <td>example@example.com	</td>
                      <td>01 August 2020</td>
                      <td>
                        <div className="d-flex">
                          <Link to="#" className="btn btn-primary shadow btn-xs sharp me-1"><i className="fa fa-pencil"></i></Link>
                          <Link to="#" className="btn btn-danger shadow btn-xs sharp"><i className="fa fa-trash"></i></Link>
                        </div>
                      </td>
                    </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> : <Navigate to='/' />
  )
}

function Institute(props) {
  const setEdit = props.setEdit;
  const setInstituteId = props.setInstituteId

  const Edit = () => {
    setEdit(true)
    setInstituteId(props.id)

  }
  // console.log(props)
  return (
    <tr>
      <td>
        <div className="form-check custom-checkbox checkbox-success check-lg me-3">
          <input type="checkbox" className="form-check-input" id="customCheckBox2" required="" />
          <label className="form-check-label" htmlFor="customCheckBox2"></label>
        </div>
      </td>
      <td><strong>{props.id}</strong></td>
      <td><div className="d-flex align-items-center"><img src="images/avatar/1.jpg" className="rounded-lg me-2" width="24" alt="" /> <span className="w-space-no">{props.institute_name}</span></div></td>
      <td>{props.code}</td>
      <td>{props.created_at}</td>
      <td>
        <div className="d-flex">
          <button data-bs-toggle="modal" data-bs-target="#addNewModal" onClick={(e) => Edit(e)} className="btn btn-primary shadow btn-xs sharp me-1"><i className="fa fa-pencil"></i></button>
          <button className="btn btn-danger shadow btn-xs sharp"><i className="fa fa-trash"></i></button>
        </div>
      </td>
    </tr>
  )
}