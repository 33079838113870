import React, { useContext, useEffect } from 'react'
import NavTitleContext from '../context/NavTitleProvider';

const FeaturedCourses = () => {
  const {setNavbarText} = useContext(NavTitleContext)
  useEffect(()=>{
    setNavbarText("Featured Courses")
},[])
  return (
    <div className="content-body pe-0 mt-3">
      <div className="ms-5">
        <h2>Featured Courses</h2>
      </div>
    </div>
  )
}
export default FeaturedCourses;