import axios from "axios";
// export const BASE_URL = 'http://127.0.0.1:8000/api/v1/admin';
// export const MEDIA_URL = 'http://127.0.0.1:8000';

export const BASE_URL = 'https://lms-beta-api.foxa.in/api/v1/admin';
export const MEDIA_URL = 'https://lms-beta-api.foxa.in';

export default axios.create({
    baseURL: BASE_URL
})

export const axiosPrivate=axios.create({
    baseURL: BASE_URL,
    headers:{
        'Content-Type': "application/json",
        
    },
    withCredentials:false
});