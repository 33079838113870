import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useState, useEffect, useContext } from "react"
import ASSET_TYPES from "../components/AssetTypes"
import AssetForm from "../components/AssetUploadForm"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import NavTitleContext from '../context/NavTitleProvider';

export default function AssetEdit(props) {
    const [assetType, setAssetType] = useState('video')
    const [assetList, setAssetList] = useState('')
    const axiosPrivate = useAxiosPrivate()
    const {setNavbarText} = useContext(NavTitleContext)
    useEffect(()=>{
        setNavbarText("Assets")
    },[])

    useEffect(() => {
        getAssetList()
    }, [])

    const getAssetList = async (e) => {
        let response = await axiosPrivate.get("/asset/recent_list/")
        // console.log(response.data)
        if (response.data) {
            setAssetList(response.data.data)
        }


    }
    
    return (
        <div className="content-body pe-0 mt-3">
            <div className="ms-5">
                <h2>Asset Upload</h2>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="">
                        <div className="">
                            <div className="">
                                <div className="default-tab">
                                    {/* <ul className="nav nav-tabs" role="tablist"> */}
                                    <Tabs
                                        defaultActiveKey="video"
                                        id="tablist"
                                        className=''
                                        justify
                                    >
                                        {ASSET_TYPES.map((data, index) => {
                                            return (

                                                <Tab key={"tab"+index.toString()} eventKey={data.value} title={data.name}>
                                                    <div className='row pt-4'>
                                                        <AssetForm edit={false} getAssetList={getAssetList} assetList={assetList} name={data.name} />
                                                    </div>
                                                </Tab>
                                                //             <li key={index.toString()} className="nav-item col">
                                                //                 <button onClick={()=>setAssetType(data.value)} className={data.id == 1 ? "col-12 nav-link  active":"col-12 nav-link "} data-bs-toggle="tab" data-bs-target={"#" + data.value} >

                                                //                     {data.name}</button>
                                                //             </li>)
                                                //     })}
                                                // </ul>
                                                // <div className="tab-content">
                                                //     {ASSET_TYPES.map((data,index) => {
                                                //         return (
                                                //             <>
                                                //                 <div key={"data"+index.toString()} className={data.id == 1 ? "tab-pane fade show active" : "tab-pane fade"} id={data.value} role={data.id == 1 ? "tabpanel":""}>
                                                //                     <div className="pt-4 row">
                                                //                         <AssetForm assetList={assetList} name={data.name} />
                                                //                     </div>
                                                //                 </div>
                                                //             </>
                                            )
                                        })}

                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}