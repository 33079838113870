
import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom'
import RequireAuth from './components/RequireAuth';


// import Navbar from './components/Navbar';
import Home from "./pages/Home"
import Dashboard from './pages/Dashboard';
import Courses from './pages/Courses';
import Packages from './pages/Packages';
import AssetEdit from './pages/AssetEdit';
import Login from './pages/Login';
import Register from './pages/Register';
import InstituteList from './pages/institute/InstituteList';
import Layout from './components/Layout';
import { useState } from 'react';
import CourseComponents from './pages/CourseComponents';
import UserList from './pages/UserList';
import FeaturedCourses from './pages/FeaturedCourses';
import Banners from './pages/Banners';
// import Sidebar from './components/Sidebar';
// import Footer from './components/Footer';


function App() {
  return (
    // <div className="App">
    //   <Home/>
    //   {/* <header className="App-header">
    //     Header
    //   </header> */}
    // </div>

    <Routes>
      <Route path='/' element={<Layout />}>

        {/* needs protection */}
        <Route element={<RequireAuth />}>
          <Route path='/' element={<Home />}>
            <Route path='institutelist' element={<InstituteList />} />
            <Route index element={<Dashboard />} />
            <Route path='userlist' element={<UserList />}/>
            <Route path='courses' element={<Courses />}/>
            <Route path='components' element={<CourseComponents />}/>
            <Route path='packages' element={<Packages />} />
            <Route path="assets" element={<AssetEdit />} />
            <Route path="featured" element={<FeaturedCourses />} />
            <Route path="banners" element={<Banners />} />
          </Route>
        </Route>

        {/* public routes */}

        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
      </Route>
    </Routes>

    // <Routes>
    //   <Route path='/' element={<Home />}>
    //     <Route path='institutelist' element={<InstituteList/>}/>
    //     <Route path='dashboard' element={<Dashboard />} />
    //     <Route path='courses' element={<Courses />} />
    //     <Route path='packages' element={<Packages />} />
    //     <Route path='courseedit/:id' element={<CourseEdit />} />
    //     <Route path='packageedit/:id' element={<PackageEdit />} />
    //     <Route path='assets' element={<Assets />} />
    //     <Route index element={<AssetEdit />} />
    //     <Route path='assetview' element={<AssetView />} />
    //   </Route>
    //   <Route path='/login' element={<Login/>}/>
    //   <Route path='/register' element={<Register/>}/>
    // </Routes>

  );
}

export default App;
