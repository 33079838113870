import { axiosPrivate } from "../api/axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";

const useAxiosPrivate = ()=>{
    // const refresh = useRefreshToken();
    const {auth}=useAuth();

    useEffect(()=>{

        axiosPrivate.interceptors.request.use(
            config=>{
                
                // if(!config.headers['Authorization']){
                    config.headers['Authorization'] = `Token ${auth?.accessToken}`;
                    
                // }
                return config;
            },(error)=>{
                Promise.reject(error)}
        );

        axiosPrivate.interceptors.response.use(
            response => response,
            async (error)=>{
                const prevRequest = error?.config;
                if(error?.response?.status === 403 && !prevRequest?.sent){
                    prevRequest.sent = true;
                    // const newAccessToken = await refresh();
                    // prevRequest.header['Authorization'] = `Token ${newAccessToken}`;
                    return axiosPrivate(prevRequest);
                }
                return Promise.reject(error)
            }
        );
        // return() => {
        //     axiosPrivate.interceptors.request.eject(requestIntercept)
        //     axiosPrivate.interceptors.response.eject(responseIntercept)
        // }
    },[auth])

    return axiosPrivate
}
export default useAxiosPrivate;