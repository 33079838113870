import { Link, useNavigate } from "react-router-dom";
import logo_full from "../assets/images/logo-full.png"
import log from "../assets/images/log.png"
import { useState, useEffect } from "react";
import { BASE_API_URL } from "../shared/global";
import Swal from "sweetalert2";
import axios from "../api/axios";

const REGISTER_URL = '/company/register/'

export default function Register() {
    const [companyName, setCompanName] = useState('');
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userMobile, setUserMobile] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const navigate = useNavigate();

    // const data = new FormData()
    const handleSubmit = async (e) => {


        e.preventDefault();


        //NORMAL API


        //JSON DATA
        let data = JSON.stringify({
            company_name: companyName,
            username: userName,
            email: userEmail,
            mobile: userMobile,
            password: userPassword,
        })
        try {
            const response = await axios.post(REGISTER_URL, data,
                {
                    headers: { 'Content-Type': 'application/json' },
                    // withCredentials: true
                }
            );
            
            if (response?.status === 200) {
                setCompanName("");
                setUserName("");
                setUserEmail("");
                setUserMobile("")
                setUserPassword("");
                // alert("Asset Uploded Successfully");

                // console.log(res)
                if (response?.data?.success == true) {
                    Swal.fire("Hey, Good job !!", "Company created successfully", "success")
                    navigate('/login')
                }
                else {
                    Swal.fire(response?.data?.errors, response?.data?.message, "warning")
                    navigate('/login');
                }
            } else {
                // alert("Some error occured");
                Swal.fire("Oops...", "Something went wrong !!", "error")
                // console.log(response)
            }
        } catch (err) {
            // console.log(err)
        }

        // try {
        //     let res = await fetch(`${BASE_API_URL}/company/register/`, {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json'
        //         },
        //         body: newdata,
        //     });
        //     let resJSON = await res.json();
        //     console.log(resJSON)
        //     if (res.status === 200) {
        //         setCompanName("");
        //         setUserName("");
        //         setUserEmail("");
        //         setUserMobile("")
        //         setUserPassword("");
        //         // alert("Asset Uploded Successfully");

        //         // console.log(res)
        //         if(resJSON.success == true){
        //         Swal.fire("Hey, Good job !!", "You clicked the button !!", "success")
        //         navigate('/login')}
        //         else{
        //             Swal.fire(resJSON.errors, resJSON.message, "warning")
        //         navigate('/login');
        //         }
        //     } else {
        //         // alert("Some error occured");
        //         Swal.fire("Oops...", "Something went wrong !!", "error")
        //         console.log(res)
        //     }


        // } catch (err) {
        //     console.log(err);
        // }
    }

    return (
        <div className="container h-100">
            <div className="row h-100 align-items-center justify-contain-center pt-5">
                <div className="col-xl-12 pt-5">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="row m-0">
                                <div className="col-xl-6 col-md-6 sign text-center">
                                    <div>
                                        <div className="text-center my-5">
                                            <a href="index.html"><img width="200" src={logo_full} alt="" /></a>
                                        </div>
                                        <img src={log} className="education-img"></img>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-md-6">
                                    <div className="sign-in-your">
                                        <h4 className="text-center mb-4 fw-bolder">Sign up your account</h4>
                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                <label className="mb-1"><strong>Company Name</strong></label>
                                                <input onChange={(e) => setCompanName(e.target.value)} type="text" className="form-control" placeholder="comapany name" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="mb-1"><strong>Username</strong></label>
                                                <input onChange={(e) => setUserName(e.target.value)} type="text" className="form-control" placeholder="username" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="mb-1"><strong>Email</strong></label>
                                                <input onChange={(e) => setUserEmail(e.target.value)} type="email" className="form-control" placeholder="hello@example.com" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="mb-1"><strong>Mobile. No</strong></label>
                                                <input onChange={(e) => setUserMobile(e.target.value)} type="number" className="form-control" placeholder="mobile no" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="mb-1"><strong>Password</strong></label>
                                                <input onChange={(e) => setUserPassword(e.target.value)} type="password" className="form-control" />
                                            </div>
                                            <div className="text-center mt-4">
                                                <button type="submit" className="btn btn-primary btn-block">Sign me up</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}