import { useState, useEffect, useContext } from "react"
import Modal from "../components/Model"
import NavTitleContext from "../context/NavTitleProvider"
import useAxiosPrivate from "../hooks/useAxiosPrivate"

const UserList = (props) => {
    const [userList, setUserList] = useState(null)
    const [currentUser, setCurrentUser] = useState('')
    const [userInfo, setUserInfo] = useState({})
    // const [keys, setKeys] = useState([])
    const [isActive, setIsActive] = useState(null)

    const axiosPrivate = useAxiosPrivate()

    const LIST = [{ id: 1, name: "User1", email: "demo@123.com", phone: "9876543210", is_active: true }, { id: 2, name: "User2", email: "demo1@123.com", phone: "8876543210", is_active: false }, { id: 3, name: "User3", email: "demo2@123.com", phone: "6576543210", is_active: false }, { id: 4, name: "User4", email: "demo3@123.com", phone: "9276543210", is_active: true }]


    const getUserList = async () => {
        try {
            let URL = '/users/'
            let response = await axiosPrivate.get(URL)
            // console.log(response?.data?.data)
            setUserList(response?.data?.data)
        }
        catch (err) {
            // console.log(err)
        }
    }
    useEffect(() => {
        getUserList();
    }, [])

    // useEffect(() => {
    //     if (userList) {
    //         let keys = Object.keys(userList[0])
    //         console.log(keys)
    //         setKeys(keys)
    //     }
    // }, [userList])
    useEffect(() => {
        if (userList) {
            // console.log(userList[currentUser])

            let data = userList[currentUser]
            setUserInfo(data)
            // console.log("Data")
            // console.log(data)
        }
    }, [currentUser,])

    const {setNavbarText} = useContext(NavTitleContext)
    useEffect(()=>{
        setNavbarText("Users")
    },[])


    return (
        <div className="content-body pe-0">
            <div className="container-fluid">
                <div className="row widget-heading mb-5">
                    <div className="col-10">
                        <div className="input-group search-area">

                            <input type="text" className="form-control" placeholder="Search here..." /><span className="input-group-text"><a href="#"><svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z" fill="var(--secondary)" />
                            </svg>
                            </a></span>
                        </div></div>
                    <div className="col-2">
                        {/* <Link to="courses.html" className="btn btn-primary btn-md col-12 fs-1">Add</Link> */}
                        <button type="button" className="btn btn-primary mb-2 float-end btn-md" data-bs-toggle="modal" data-bs-target="#addNewModal">
                            Add New
                        </button>
                    </div>
                </div>

                <div className="row">
                    <div className="swiper course-slider">
                        <div className="swiper-wrapper">
                            <div className="">
                                <div className="card-body">
                                    {/* <div className="widget-courses align-items-center d-flex justify-content-between flex-wrap"> */}
                                    <div className="row">
                                        {/* <img src="images/svg/color-palette.svg" alt="" /> */}
                                        <div className="col-2">
                                            <h4 className="">Name</h4>
                                        </div>
                                        <div className="col-3">
                                            <h4>Email</h4>
                                        </div>
                                        <div className="col-3">
                                            <h4>Mobile</h4>
                                        </div>

                                        <div className="col-2">
                                            <h4>Active/Inacive</h4>
                                        </div>
                                        <div className="col-1">

                                        </div>
                                    </div>

                                    {/* <Link	 ><i className="las la-angle-right text-primary"></i></Link> */}
                                    {/* </div> */}
                                </div>
                            </div>

                            {userList && userList.map((e, index) => <ListItem key={"current" + index.toString()} index={index} setCurrentUser={setCurrentUser} {...e} />)}
                            <Modal size="lg" id="userinfo" header="User Info">
                                <div className="row">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-2">
                                                    {userInfo.image?userInfo.image:"LOGO"}
                                                </div>
                                                <div className="col-8">
                                                    <div className="">
                                                        <h2>{"@" + userInfo.username}</h2>
                                                    </div>
                                                    <div className="">
                                                        <h4 className="">{new Date(userInfo.created_at).toDateString().slice(4)}</h4>
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    <div className="align-bottom">
                                                        {userInfo && <button onClick={() => setUserInfo({ ...userInfo, is_active: userInfo.is_active ? false : true })} className={userInfo.is_active ? "btn btn-danger" : "btn btn-success"}>{userInfo && userInfo.is_active ? "Deactivate" : "Activate"}</button>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card bg-light">
                                        <div className="card-body">
                                            <div className="row">
                                                {/* <div className="">
                                                    <h3>Info</h3>
                                                </div> */}
                                                <div className="mt-3">
                                                    <div className="row px-5">
                                                        <div className="col-6 h3">
                                                            Name
                                                        </div>
                                                        <div className="col-6 h4">
                                                            <span>{":  "}</span>
                                                            <span>{userInfo && userInfo.first_name + " " + userInfo.middle_name && userInfo.middle_name + " " + userInfo.last_name}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row px-5">
                                                        <div className="col-6 h3">
                                                            Email
                                                        </div>
                                                        <div className="col-6 h4">
                                                            <span>{":  "}</span>
                                                            <span>{userInfo&&userInfo.email}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row px-5">
                                                        <div className="col-6 h3">
                                                            Mobile
                                                        </div>
                                                        <div className="col-6 h4">
                                                            <span>{":  "}</span>
                                                            <span>{userInfo&&userInfo.mobile}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}


const ListItem = (props) => {
    const { setCurrentUser } = props;
    return (
        // <div className="swiper-slide">
        <div className="card">
            <div className="card-body">
                {/* <div className="widget-courses align-items-center d-flex justify-content-between flex-wrap"> */}
                <div className="row">
                    {/* <img src="images/svg/color-palette.svg" alt="" /> */}
                    <div className="col-2">
                        <h4 className="">{props.first_name + " " + props.middle_name && props.middle_name + " " + props.last_name}</h4>
                    </div>
                    <div className="col-3">
                        <h4>{props.email}</h4>
                    </div>
                    <div className="col-3">
                        <h4>{props.mobile}</h4>
                    </div>
                    <div className="col-2">
                        <button className={props.is_active ? "btn btn-sm btn-danger " : "btn btn-sm btn-success "} >{props.is_active ? "Deactivate" : "Activate"}</button>
                    </div>
                    <div className="col-1">
                        <button data-bs-target="#userinfo" onClick={() => setCurrentUser(props.index)} data-bs-toggle="modal" className="btn btn-sm btn-primary">View</button>
                    </div>
                </div>
                {/* <Link	 ><i className="las la-angle-right text-primary"></i></Link> */}
                {/* </div> */}
            </div>
        </div>
        // </div>
    )
}









export default UserList;