import { data } from "jquery";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Modal from "../Model"

const list1 = [{ id: "1", data: "One" }, { id: "2", data: "Two" }, { id: "3", data: "Three" }, { id: "4", data: "Four" }]
const list2 = [{ id: "5", data: "Five" }, { id: "6", data: "Six" }, { id: "7", data: "Seven" }, { id: "8", data: "Eight" }]


const DragList = (props) => {
    const [idList, setIdList] = useState([])
    const [children, setChildren] = useState([])

    const axiosPrivate = useAxiosPrivate()

    const { contentList } = props;
    const {courseContentList}=props
    console.log(courseContentList)
    const {getCourseContent}=props


    useEffect(() => {
        setChildren(courseContentList && courseContentList)
    }, [courseContentList])

    useEffect(() => {
        if (courseContentList) {
            let children = courseContentList;
            let idList = children.map((e) => e.content.id);
            setIdList(idList)
        }
    }, [children])

    const course_id = props.courseId;

    const cName = (data) => {
        let newdata = data
        let first = data[0].toUpperCase() + data.substring(1) + "s"

        return first
    }
    // const contentName = cName(contentList[0].content_type);

    // console.log(props)
    function allowDrop(ev) {
        ev.preventDefault();
    }

    function drag(ev) {
        // console.log(ev.target)
        ev.dataTransfer.setData("text", ev.target.id);
    }

    function drop(ev) {
        ev.preventDefault();
        // console.log(ev.target)
        var data = ev.dataTransfer.getData("text");
        let element = document.getElementById(data).cloneNode(true);
        // element.setAttribute('draggable', false);
        // let elem = <li className="list-group-item border mb-2"><span>element</span> <span className="float-end">x</span></li>
        // let item = ev.target.id.slice(-1)

        // console.log(id_list)
        let item_id = element.value;
        // console.log(idList)
        // if (id_list[target] != undefined) {
        if (!idList.includes(item_id)) {
            setIdList([...idList, item_id])
            handleSubmit(item_id, course_id)
            // ev.target.appendChild(element);

        }
        else {
            Swal.fire("", "Already exist", "warning")
        }
        data = null;
        // course_topic_bind(topic_id, asset_id);

    }

    const handleSubmit = async (item_id, course_id) => {
        try {
            let data = JSON.stringify({
                content_id: item_id,
                course_id: course_id,
                order: contentList.length + 1
            })
            let URL = '/content_course/'

            let response =await axiosPrivate.post(URL, data)
            if (response?.data?.success) {
                Swal.fire("", "Added", "success")
                getCourseContent()

            }


        } catch (err) {
            // console.log(err)
        }
    }
    const handleDelete = async (e, itemId) => {

        try {
          var  Url = "/content_course/un_assign/"
          let data = JSON.stringify({
              course_id: props.courseId,
              content_id: itemId
            })
          
          let response = await axiosPrivate.post(Url, data)
          if (response?.data?.success) {
            Swal.fire("", "Deleted", "success")
    
            getCourseContent()
          }
        } catch (err) {
          // console.log(err)
        }
      }





    return (
        <div className="">
            <div className="row p-2">
                <div className="col-6">
                    <div className="m-3 col-12 bg-white rounded">

                        <h4 className="ms-3 mt-3 col-9">{props.header}</h4>

                        <div className="accordion accordion-flush" id="accordionFlushExample">


                            <div className="accordion-item">
                                <div id="flush-collapse" className="" aria-labelledby={"flush-heading"} data-bs-parent="#accordionFlushExample">
                                    <ul id="" className="accordion-body border mt-2 ms-2" style={{ height: "300px" }} onDrop={(event) => drop(event)} onDragOver={(event) => allowDrop(event)}>
                                        {/* <input type="hidden"/> */}
                                        {courseContentList && courseContentList.map((item, index) => {
                                            return (
                                                <li key={"course"+index} draggable="false" value={item.course.id} id={"topic" + index + ""}
                                                    //    onDragStart={(e) => drag(e)}
                                                    className="list-group-item border mb-2">
                                                    <div className="row" >
                                                        <div className="col-10">

                                                            <i className="fas fa-play-circle me-2"></i>
                                                            {item.content.name}

                                                        </div>
                                                        <div className="col-2">
                                                            <button onClick={(e) => handleDelete(e, item.content.id)} className="btn">x</button>
                                                        </div>
                                                        <div className="value"><input type="hidden" value={item.content.id} /></div>
                                                    </div>
                                                </li>)
                                        })}
                                        {/* <div id={"course_topic"} onDragStart={(event) => drag(event)}>
                              <div>
                                
                                <div className="value"><input type="hidden" /></div>
                              </div>
                            </div> */}
                                    </ul>
                                </div>
                            </div>

                            {/* </>)
                })} */}
                            {/* </form> */}
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="m-3 p-2 col-12 bg-white">
                        <h4 className="ms-3 mt-1 mb-3">{contentList && cName(contentList[0]?.content_type)}</h4>
                        <ul className="list-group">

                            {contentList && contentList.map((item, index) => {
                                return (
                                    <li key={"component"+item.id} draggable="true" value={item.id} id={"topic" + index + ""} onDragStart={(e) => drag(e)} className="list-group-item border mb-2">
                                        <div >
                                            <div>

                                                <i className="fas fa-play-circle me-2"></i>
                                                {item.name}

                                            </div>
                                            <div className="value"><input type="hidden" value={item.id} /></div>
                                        </div>
                                    </li>)
                            })}

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DragList;
