import { Children, createContext,useState } from "react";


const NavTitleContext = createContext();
export default NavTitleContext;

export const NavTitleProvider = ({children})=>{
    const [navbarText, setNavbarText] = useState('Navbar')

    return(
        <NavTitleContext.Provider value={{navbarText,setNavbarText}}>
            {children}
        </NavTitleContext.Provider>
    )
};
