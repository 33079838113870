import { useContext, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DragBox, DropBox } from "../components/Dragable/Drag";
import NavTitleContext from "../context/NavTitleProvider";

const CourseComponents = (props) => {

    const [title, setTitle] = useState("Subject")

    const COMPONENT_TYPES = [{ id: 1, name: 'Subject', value: 'subject', parentUrl: "/subjects/chapters/",childUrl:"/chapters/" }, { id: 2, name: 'Chapter', value: 'chapter', parentUrl: "/chapters/topics/", childUrl:"/topics/"}, { id: 3, name: 'Topic', value: 'topic', parentUrl: "/topics/assets/", childUrl:"/asset/" }]
    const components = [{ id: 1, name: 'Component1' }, { id: 2, name: 'Component2' }, { id: 3, name: 'Component3' }, { id: 4, name: 'Component4' }, { id: 5, name: 'Component5' }]
    const filters = ['Video', 'Audio', 'Document']
    const {setNavbarText} = useContext(NavTitleContext)
    useEffect(()=>{
        setNavbarText("Components")
    },[])

    return (
        <div className="content-body pe-0 mt-3">
            <div className="ms-5">
                <h2>{title} Upload</h2>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="">
                        <div className="">
                            <div className="">
                                <div className="default-tab">
                                    {/* <ul className="nav nav-tabs" role="tablist">
                                        {COMPONENT_TYPES.map((data,index) => {
                                            return (
                                                <li key={index.toString()} className="nav-item col">
                                                    <button onClick={()=>setTitle(data.name)} className={ + data.id == 1 ? "col-12 nav-link active":"col-12 nav-link"} data-bs-toggle="tab" data-bs-target={"#" + data.value}>
                                                        {data.name}</button>
                                                </li>)
                                        })}
                                    </ul> */}
                                    <Tabs
                                        defaultActiveKey="subject"
                                        id="tablist"
                                        className=''
                                        justify
                                    >
                                            {COMPONENT_TYPES.map((data, index) => {
                                                return (
                                                    <Tab key={"tab"+index} eventKey={data.value} title={data.name}>
                                                        <div className='row pt-4'>

                                                            {/* <div key={data.toString()} className={data.id == 1 ? "tab-pane fade show active" : "tab-pane fade"} id={data.value} role={data.id == 1 ? "tabpanel" : ""}> */}
                                                            {/* <div className="pt-4 row"> */}
                                                            {/* <AssetForm name={data.name} /> */}
                                                            {/* <DragList header={data.name}/> */}
                                                            <DropBox components={components} url={data.parentUrl} {...data} add={true} header={data.name} />
                                                            <DragBox url={data.childUrl} filters={filters} search={true} />

                                                            {/* </div> */}
                                                        </div>
                                                    </Tab>
                                                )
                                            })}
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}

const Component = (props) => {
    return (
        <>
        </>
    )
}

export default CourseComponents