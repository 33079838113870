import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, } from 'react-router-dom'
import $ from 'jquery';

import './index.css';
import App from './App';
import { AuthProvider } from './context/AuthProvider';
import { NavTitleProvider } from './context/NavTitleProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <NavTitleProvider>
          <Routes>
            <Route path="/*" element={<App />} />
            {/* <App/> */}
          </Routes>
        </NavTitleProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);