import Modal from "./Model"
import Swal from "sweetalert2";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import ASSET_TYPES from "./AssetTypes";
import AssetForm from "./AssetUploadForm";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export function VideoPreview(props) {
    if (props.type == 'youtube') {
        let video_url = props.url.slice(-11)
        return (
            <div className="text-center">
                <iframe className="col-12" height={200} src={"https://www.youtube.com/embed/" + video_url} frameBorder="0"></iframe>
            </div>
        )
    } else if (props.type == 'vimeo') {
        let video_url;
        if (props.url.includes("vimeo.com/video/")) {
            let index = props.url.indexOf("video/")

            video_url = props.url.slice(index + 6, index + 15)
            // alert(video_url)
        }
        else if (props.url.includes("vimeo.com/")) {
            let index = props.url.indexOf("com/")

            video_url = props.url.slice(index + 4, index + 13)
            // alert(video_url)
        }
        return (
            <div className="text-center">
                <iframe className="col-12"  height="200" src={"https://player.vimeo.com/video/" + video_url} frameBorder="0"></iframe>
            </div>
        )
    }
}

export function RecentUploads(props) {
    const [assetName, setAssetName] = useState("")
    const [assetData, setAssetData] = useState({})
    
    // console.log(props)
    const {getAssetList} = props
    const assetList = props.assetList;
    const allAssets = props.assetList
    const assetShow = allAssets && allAssets.map((data, index) => <RecentItems  key={"list" + index.toString()} {...data} />)
    const assetItems = assetList && assetList.map((data, index) => <RecentItem getAssetList={getAssetList} key={"item" + index.toString()} setAssetData={setAssetData} setAssetName={setAssetName} {...data} />)
    // console.log("AssetData")
    // console.log(assetList)

    return (
        <div className="" style={{ maxHeight: "300px" }}>
            {assetShow && assetShow.slice(0, 5)}
            {/* {assetShow.length > 5 && ( */}
            <>
                {/* <Link> */}
                <button data-bs-toggle="modal" data-bs-target="#viewall" className="btn">View All &gt;</button>
                {/* </Link> */}
                <Modal size="xl" id="viewall" header="Recent Uploads">
                    <div className="row">
                        {/* <Tabs
                                defaultActiveKey={ASSET_TYPES[0].value}
                                id="prevassets"
                                className=""
                                justify> */}
                        {/* {ASSET_TYPES.map((e, index) => <Tab className="" eventKey={ASSET_TYPES[index].value} title={ASSET_TYPES[index].name}> */}
                        <div className="col-12">
                            <div className="input-group search-area">

                                <input type="text" className="form-control" placeholder={"Search assets"} /><span className="input-group-text"><a href="#"><svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z" fill="var(--secondary)" />
                                </svg>
                                </a></span>
                            </div></div>
                        {assetItems}
                        <Modal size="lg" id="assetEdit" header="Edit Asset">
                            <div className="row">
                                <AssetForm assetData={assetData} edit={true} name={assetName && assetName} />
                            </div>
                        </Modal>
                        {/* </Tab>)} */}
                        {/* </Tabs> */}
                    </div>
                </Modal>
            </>
            {/* )} */}
        </div>
    )
}

const RecentItems = (props) => {
    return (
        <div className="">
            <div className="card">
                <div className="row">
                    <div className="col-6">
                        {props.asset_title}
                    </div>
                    <div className="col-6">
                        {props.asset_description}
                    </div>
                </div>
            </div>
        </div>

    )
}

const RecentItem = (props) => {
    const { setAssetName, setAssetData } = props
    const name = props.asset_category&&props.asset_category.toString()
    const assetname = name && name[0].toUpperCase() + name.slice(1)
    const assetId = props.id;
    const {getAssetList}=props
    // console.log("AssetName:" + assetname)
    const axiosPrivate = useAxiosPrivate()

    const handleEdit = () => {
        setAssetName(assetname);
        setAssetData(props)

    }

    const handleDelete = async () => {
        try {
            let URL = '/asset/' + assetId + '/'
            let response = await axiosPrivate.delete(URL)
            if (response?.data?.success) {
                let data = true
                return  data
            }
            else {
                let data = true
                return data
            }
        } catch (e) {
            // console.log(e)
        }
    }

    const clickDelete = () => {
        Swal.fire({
            text: 'Are you sure?',
            icon: 'warning',
            buttons: ['No', 'Yes'],
            dangerMode: true,
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            closeOnConfirm: false,
            closeOnCancel: true,
        }).then(async (willContinue) => {
            // console.log(willContinue)
            if (willContinue.isConfirmed) {
                // User clicked "Yes"
                // Perform some action
                let status = await handleDelete()
                // console.log(status)
                if (status === true) {
                    Swal.fire({
                        text: 'Deleted Successfully',
                        icon: 'success',
                    },);
                    getAssetList()
                }
                else {
                    Swal.fire({
                        text: 'Deletion Failed',
                        icon: 'error',
                    },);
                }
            } else {
                // User clicked "No"
                Swal.fire({
                    text: "Cancelled",
                    icon: 'error',
                });
            }
        });
    }
    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-4">
                        <h4 className="">{props.asset_title}</h4>
                    </div>
                    <div className="col-4">
                        <h4>{props.asset_description.slice(0, 30) + "..."}</h4>
                    </div>
                    {/* <div className="col-3">
                        <h4>{props.phone}</h4>
                    </div> */}
                    <div className="col-2">
                        <button className={props.is_active ? "btn btn-sm btn-success " : "btn btn-sm btn-danger "} >{props.is_active ? "Deactivate" : "Activate"}</button>
                    </div>
                    <div className="col-1">
                        <button data-bs-target="#assetEdit" onClick={() => handleEdit()} data-bs-toggle="modal" className="btn btn-sm btn-primary">Edit</button>
                    </div>
                    <div className="col-1">
                        <button onClick={() => clickDelete()} data-bs-toggle="modal" className="btn btn-sm btn-danger">Delete</button>
                    </div>
                </div>
            </div>
        </div>
    )

}