import edit_icon from '../../assets/images/icons/edit_icon.png';

export const EditButton = (props, { element, editFun, id }) => {
    return (
        <div className="" >
            <button data-bs-toggle="modal" data-bs-target={`#${element}`} onClick={() => editFun(id)} className={"btn btn-sm text-success mt-1 " + props.className}>
                <img src={edit_icon} alt="" />

            </button>
        </div>
    )
}

export const PublishButton = (props) => {
    return (
        <div className="">
            <button
                onClick={(e) => props.editFun(e, props.id)}
                className={props.is_active ? "btn btn-sm btn-outline-danger col-12 " + props.className : "btn btn-sm btn-outline-primary col-12 " + props.className} >
                {props.is_active ? "Un-publish" : "Publish"}
            </button>
        </div>
    )
}

export const DeleteButton = (props) => {
    return (
        <div className="">
            <button onClick={(e)=>props.DeleteFun(e,props.id)}
            className={"btn  py-0 px-2 btn-outline-danger "+props.className}>
                <i className="bi bi-trash-fill fs-3"></i>
            </button>
        </div>
    )
}