function Footer(){
    return(
        <>
        <div className="footer">
            <div className="copyright">
                {/* <p>Copyright © Designed   by <a href="https://themeforest.net/user/hugebinary" target="_blank">HugeBinary</a> 2022</p> */}
            </div>
        </div>
    
        </>
    )
}
export default Footer;