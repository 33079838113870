import { data } from "jquery";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Modal from "../Model"

const list1 = [{ id: "1", data: "One" }, { id: "2", data: "Two" }, { id: "3", data: "Three" }, { id: "4", data: "Four" }]
const list2 = [{ id: "5", data: "Five" }, { id: "6", data: "Six" }, { id: "7", data: "Seven" }, { id: "8", data: "Eight" }]




export const DropBox = (props) => {
  const [contents, setContents] = useState([])
  const [searchData, setSearchData] = useState("")

  const axiosPrivate = useAxiosPrivate()

  const { url } = props


  useEffect(() => {
    getContent()
  }, [])

  const Search = () => {
    getContent(true)
  }

  const getContent = async (search) => {
    var response
    if (search || searchData != "") {
      response = await axiosPrivate.get(url, {
        params: {
          keyword: searchData
        }
      })
    }
    else {


      response = await axiosPrivate.get(url)

    }
    // console.log("Content")
    // console.log(response?.data?.data?.results)
    setContents(response?.data?.data?.results)
  }

  return (
    <div className="col-12 col-md-6">
      <div className="m-3 col-12 bg-white rounded" style={{ minHeight: "400px" }}>
        <div className="row mb-4">
          <div className="col-6">
            <h4 className="ms-3 mt-3 col-9">{props.header}</h4>
          </div>
          {props.add && <AddItem {...props} getContent={getContent} />}
        </div>


        <div className="accordion accordion-flush" id="accordionFlushExample">
          <div className="col-12 p-1 pb-3">
            <div className="input-group search-area">

              <input value={searchData} onChange={(e) => setSearchData(e.target.value)} type="text" className="form-control" placeholder={"Search assets..."} />
              <span className="input-group-text bg-primary">
                <button onClick={() => { Search() }} className="btn btn-sm p-0"><svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z" fill="var(--secondary)" />
                </svg>
                </button>
              </span>
            </div>
          </div>
          {/* <form > */}


          <div className="accordion-item">
            {contents && contents.map((e, index) => <DropItem contents={contents} getContent={getContent} key={"drop" + index.toString()} {...e} index={index} />)}
          </div>

          {/* </form> */}
        </div>
      </div>
    </div>
  )
}

export const DropItem = (props) => {
  const [idList, setIdList] = useState([])
  const [children, setChildren] = useState([])

  const [itemId, setItemId] = useState('')
  const [parentId, setParentId] = useState('')

  const axiosPrivate = useAxiosPrivate()

  const { url } = props;
  const { contents } = props
  const { getContent } = props
  // console.log("DropBox"); console.log(props)

  useEffect(() => {
    setChildren(props?.children ? props.children : props.assets)
  }, [contents])

  useEffect(() => {
    let children = props?.children ? props.children : props.assets;
    let idList = children.map((e) => e.id);
    setIdList(idList)
  }, [children])

  const { index } = props
  function allowDrop(ev) {
    ev.preventDefault();
  }
  const isItemIncluded = (itemToCheck) => {
    return idList.includes(itemToCheck);
  };

  const handleSubmit = async (item_id, parent_id) => {
    try {
      let length = props?.children ? props?.children.length : props?.assets?.length
      // console.log("Length:" + length)
      // console.log(props)
      var data;
      var url;
      // console.log(itemId, parentId)
      if (props.content_type == "topic") {
        data = JSON.stringify({
          asset_id: item_id,
          content_id: parent_id,
          order: length + 1

        })
        url = "/asset_content/"
      }
      else {
        data = JSON.stringify({
          child_id: item_id,
          parent_id: parent_id,
          order: length + 1
        })
        url = "/content_content/"
      }
      let response = await axiosPrivate.post(url, data)
      // console.log(response)
      if (response?.data?.success) {
        Swal.fire("", "Added", "success")

        getContent()
      }
      // let data=JSON.stringify({
      //   username: userName,
      //   password: passWord,
      // })
      // let response = await axiosPrivate.post(url)
    } catch (err) {
      // console.log(err)
    }
  }
  function drop(ev) {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    let element = document.getElementById(data).cloneNode(true);
    // element.setAttribute('draggable', false);
    // let elem = <li className="list-group-item border mb-2"><span>element</span> <span className="float-end">x</span></li>
    // let item = ev.target.id.slice(-1)

    // console.log(id_list)
    setItemId(element.value);
    setParentId(props.id)
    let item_id = element.value
    let parent_id = props.id;
    // console.log("item:" + item_id + ", parent:" + parent_id)
    // console.log(parent_id)
    // if (id_list[target] != undefined) {
    if (!idList.includes(item_id)) {
      // id_list.push(item_id);
      setIdList([...idList, item_id])
      handleSubmit(item_id, parent_id)
      // ev.target.appendChild(element);

    }
    else {
      // console.log("idList")
      // console.log(element)
      Swal.fire("", "Already exist", "warning")
    }
    data = null;
    // course_topic_bind(topic_id, asset_id);

  }

  const handleDelete = async (e, itemId) => {

    try {
      var data;
      var Url;
      if (props.content_type == "topic") {
        data = JSON.stringify({
          asset_id: itemId,
          content_id: props.id
        })
        Url = "/asset_content/un_assign/"
      }
      else {
        data = JSON.stringify({
          parent_id: props.id,
          child_id: itemId
        })
        Url = "/content_content/un_assign/"
      }
      let response = await axiosPrivate.post(Url, data)
      if (response?.data?.success) {
        Swal.fire("", "Deleted", "success")

        getContent()
      }
    } catch (err) {
      // console.log(err)
    }
  }

  return (
    <>
      <h2 key={index} className="" id={"flush-heading" + index}>
        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapse" + index} aria-expanded="false" aria-controls={"flush-collapse" + index}>
          {props.name}
        </button>
      </h2>
      <div id={"flush-collapse" + index} className="collapse" aria-labelledby={"flush-heading" + index} data-bs-parent="#accordionFlushExample">
        <ul id={"dropbox" + index} className="accordion-body border mt-2 ms-2" style={{ minHeight: "50px" }} onDrop={(event) => drop(event)} onDragOver={(event) => allowDrop(event)}>
          {children && children.map((item, index) => {
            return (
              <li key={"child" + index} draggable="true" value={item.id} id={item.content_type ? item.content_type : item.asset_category + index} className="list-group-item border mb-2">
                <div className="row" >
                  <div className="col-10">

                    <i className="fas fa-play-circle me-2"></i>
                    {item.name ? item.name : item.asset_title}

                  </div>
                  <div className="col-2">
                    <button onClick={(e) => handleDelete(e, item.id)} className="btn">x</button>
                  </div>
                  <div className="value"><input type="hidden" value={item.id} /></div>
                </div>
              </li>)
          })
          }
        </ul>
      </div></>
  )
}

export const DragBox = (props) => {
  const [contentList, setContentList] = useState([]);
  const [assetType, setAssetType] = useState(false)
  const [searchData, setSearchData] = useState("")
  const [contentSearch, setContentSearch] = useState(false)

  const axiosPrivate = useAxiosPrivate();

  const { url } = props;
  const { search } = props
  const { filters } = props;


  useEffect(() => {
    getContent()
  }, [])

  const Search = () => {
    getContent(true)
  }

  const contentName = (data) => {
    let type = data[0].toUpperCase() + data.substring(1) + "s"

    return type
  }

  const getContent = async (search) => {
    var response;;
    if (search || searchData != "") {
      response = await axiosPrivate.get(url, {
        params: {
          keyword: searchData
        }
      })

    } else {
      response = await axiosPrivate.get(url)
    }

    if (response?.data?.data?.results) {
      setContentList(response?.data?.data?.results)
      setAssetType(false)
      // console.log(response?.data?.data?.results)
    } else if (response?.data?.data) {
      setContentList(response?.data?.data)
      // console.log(response?.data?.data)
      setAssetType(true)


    }
  }


  function drag(ev) {
    // console.log(ev.target)
    ev.dataTransfer.setData("text", ev.target.id);
  }


  let data = filters && filters.map((data, index) => {
    return (
      <div className="col">
        <button type="button" className="btn btn-primary active col-12" data-bs-toggle="button" aria-pressed="false" autocomplete="off">{data}</button>
      </div>)
  })


  return (
    <div className="col-12 col-md-6">
      <div className="m-3 p-2 col-12 bg-white rounded" style={{ minHeight: "400px" }}>
        <h4 className="ms-3 mt-1 mb-3">
          {contentList[0]?.content_type ? contentName(contentList[0]?.content_type) : "Assets"}
        </h4>

        <ul className="list-group">
          {/* <div className="row mx-2 mt-3">
            {data && data}
          </div> */}
          <div className="row my-3">
            {search && <div className="col-12">
              <div className="input-group search-area">

                <input value={searchData} onChange={(e) => setSearchData(e.target.value)} type="text" className="form-control" placeholder={"Search assets..."} />
                <span className="input-group-text bg-primary">
                  <button onClick={() => { setContentSearch(true); Search() }} className="btn btn-sm p-0"><svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z" fill="var(--secondary)" />
                  </svg>
                  </button>
                </span>
              </div>
            </div>}
          </div>

          {contentList && contentList.map((item, index) => {
            return (
              <li key={"content" + index} draggable="true" value={item.id} id={item?.content_type ? item.content_type + index : "asset" + index + ""} onDragStart={(e) => drag(e)} className="list-group-item border mb-2">
                <div >
                  <div>

                    <i className="fas fa-play-circle me-2"></i>
                    {item.name ? item.name : item.asset_title}

                  </div>
                  <div className="value"><input type="hidden" value={item.id && item.id} /></div>
                </div>
              </li>)
          })}

        </ul>
      </div>
    </div>
  )
}

const AddItem = (props) => {
  const [contentName, setContentName] = useState('')
  const axiosPrivate = useAxiosPrivate()

  const content_type = props.value;
  const { getContent } = props
  // console.log("AddItem")
  // console.log(props)
  const handleSubmit = async () => {
    try {
      let data = new FormData()
      data.append("content_type", content_type)
      data.append("name", contentName)
      let URL = "/content/"
      let response = await axiosPrivate.post(URL, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      })
      // console.log(response)
      if (response?.data?.success) {
        Swal.fire("", "Successfully created", "success")
        setContentName("")
        getContent()

      }
    } catch (err) {
      // console.log(err)
    }
  }
  return (
    <div className="col-6 ">
      <button data-bs-toggle="modal" data-bs-target={"#add" + props.id} className="btn btn-primary float-end me-3 mt-3">+</button>
      <Modal id={"add" + props.id} size="md" header={"Create new " + props.name} >
        <div className="row">
          <div className="">
            <label htmlFor="">{props.name} name</label>
            <div className="">
              <input type="text" value={contentName} onChange={(e) => setContentName(e.target.value)} className="form-control" name="" id="" />
            </div>
            <div className="mt-3  ">
              <button onClick={handleSubmit} className="btn btn-primary form-control">Submit</button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}