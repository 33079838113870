import "../assets/styles/Dashboard.css"
import { useContext, useEffect, useState } from "react"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import student from "../assets/images/icons/students.png"
import employee from "../assets/images/icons/employee.png"
import course from "../assets/images/icons/course.png"
import packages from "../assets/images/icons/package.png"
import video from "../assets/images/icons/video.png"
import document from "../assets/images/icons/document.png"
import audio from "../assets/images/icons/audio.png"
import NavTitleContext from "../context/NavTitleProvider"


const DASHBOARD_URL = '/dashboard/overview/'

export default function Dashboard(props) {
	const [dashboardData, setDashboardData] = useState('')
	const axiosPrivate = useAxiosPrivate()
	useEffect(() => async function () {
		let response = await axiosPrivate.get(DASHBOARD_URL)
		setDashboardData(response?.data?.data)
	}, [])

	const { setNavbarText } = useContext(NavTitleContext)
	useEffect(() => {
		setNavbarText("Dashboard")
	}, [])


	return (
		<>
			<div className="content-body pe-0">
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12 col-xxl-12">
							<div className="row">
								<div className="col-lg-3 col-sm-6">
									<ItemCard head={true} image={student} count={dashboardData.student_count} name="Total Students" />
								</div>
								<div className="col-lg-3 col-sm-6">
									<ItemCard head={true} image={employee} count={dashboardData.user_count} name="Employee" />
								</div>
								<div className="col-lg-3 col-sm-6">
									<ItemCard head={true} image={course} count={dashboardData.course_count} name="Courses" />
								</div>
								<div className="col-lg-3 col-sm-6">
									<ItemCard head={true} image={packages} count={dashboardData.package_count} name="Packages" />
								</div>
							</div>
							<div className="row">
								<div className="col-md-8">
									<div className="col-lg-12 col-xxl-12">
										<div className="card score-active">
											<div className="card-header border-0 flex-wrap">
												<h4>Learning Activity</h4>
												<ul className="d-flex">
													<li>
														<svg className="me-2" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
															<rect x="1.5" y="1.5" width="9" height="9" rx="4.5" fill="white" stroke="var(--primary)" strokeWidth="3" />
														</svg>
														This Month
													</li>
													<li>
														<svg className="me-2" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
															<rect x="1.5" y="1.5" width="9" height="9" rx="4.5" fill="white" stroke="var(--secondary)" strokeWidth="3" />
														</svg>
														Last Month
													</li>
												</ul>
											</div>
											<div className="card-body pb-1 custome-tooltip style-1 py-0 ">
												<div id="activity"></div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="col-lg-12 col-sm-12">
										<ItemCard color="#6CB6E0" image={video} count={dashboardData.video_count ? dashboardData.video_count : 0} name="Videos" />


									</div>
									<div className="col-lg-12 col-sm-12">

										<ItemCard color="#E0A46C" image={document} count={dashboardData.document_count ? dashboardData.document_count : 0} name="Documents" />

									</div>
									<div className="col-lg-12 col-sm-12">
										
										<ItemCard color="#6CE09A" image={audio} count={dashboardData.audio_count ? dashboardData.audio_count : 0} name="Audios" />
									</div>

								</div>
							</div>
						</div>
						
					</div>
				</div>
			</div>
		</>
	)
}

const ItemCard = (props) => {
	return (
		<div className={props.head ? "rectangle-117-Qdm  p-3 pt-4 py-5 dashboard-card	" : " auto-group-srjz-uDH mb-4 dashboard-card"}>
			{props.head &&
				<div className="dashboard-relative">
					<p className="k-today-iTR" id="457:370">
						<span className="k-today-iTR-sub-0">19</span>
						<span className="k-today-iTR-sub-1"> +$76k today</span>
					</p>
				</div>
			}

			<div className="d-flex align-items-center mt-2">
				{!props.head &&
				<>
					<div style={{backgroundColor: props.color}} className="auto-group-bnxo-Ce3 m-0">
						<img className="" style={{ marginLeft: "0px" }} src={props.image} alt="" />
						
					</div>
					<div style={{borderLeft:"2px solid ",height:"40px",marginLeft:"20px"}}></div></>
					}

				<div className={props.head?"ms-2":"mx-5"}>

					<h3 className="mb-0">{props.count ? props.count : '0'}</h3>
					<span>{props.name}</span>
				</div>
				{props.head && <img className="" style={{ marginLeft: "50px", width: "40px" }} src={props.image} alt="" />}
				
			</div>
		</div>
	)
}