import DragList from "./Dragable/CourseDrag"
export default function CourseContent(props) {
    return (
        <div className="container-fluid">
            <div className="row">
                    <div className="card">
                        
                                <DragList {...props} header={props.header ? props.header+" components":"Course components"}/>
                        
                    </div>
            </div>
        </div>
    )
}