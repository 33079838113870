import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { BASE_API_URL } from "../shared/global";
import { VideoPreview, RecentUploads } from "./AssetPreview";

function AssetForm(props) {
    const [videoPreview, setVideoPreview] = useState('youtube');
    const [assetTitle, setAssetTitle] = useState('')
    const [assetThumbnail, setAssetThumbnail] = useState('')
    const [assetUrl, setAssetUrl] = useState('');
    const [assetDesc, setAssetDesc] = useState("");
    const [assetFile, setAssetFile] = useState("");
    
    const [assetId,setAssetId]=useState('')

    const [fileValue, setFileValue] = useState('')

    const axiosPrivate = useAxiosPrivate()
    
    const UrlChange = (event) => setAssetUrl(event.target.value);
    var video_type = [{ id: 1, type: 'Youtube', value: 'youtube' }, { id: 2, type: 'Vimeo', value: 'vimeo' }]

    const { getAssetList } = props
    const { edit } = props
    const { assetData } = props
    // console.log("name:" + props.name)

    useEffect(() => {
        if (edit && assetData) {
            // console.log(props)
            // console.log("AssetData")
            // console.log(assetData)
            assetData.asset_title && setAssetTitle(assetData.asset_title);
            assetData.asset_thumbnail && setAssetThumbnail(assetData.asset_thumbnail)
            assetData.asset_url && setAssetUrl(assetData.asset_url)
            assetData.asset_description && setAssetDesc(assetData.asset_description)
            assetData.asset_file && setAssetFile(assetData.asset_file)
            assetData.id && setAssetId(assetData.id)

        }

    }, [edit, assetData])

    function handleFile(event) {
        let file = event.target.files[0]
        // console.log(event)
        setFileValue(event.target.value)

        return file
        // setResetImage(event.target.value) 
    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        const Post = async (data) => {
            
            var response
            if(edit && assetId){
                let URL = '/asset/'+assetId+"/"
                response = await axiosPrivate.put(URL, data,
                {
                    headers: {
                        "Content-Type": "multipart/form-data; boundary=<calculated when request is sent>",
                        // "Content-Type":"application/json",
                    },
                });
            }
            else{
                let URL = '/asset/'
                response = await axiosPrivate.post(URL, data,
                {
                    headers: {
                        "Content-Type": "multipart/form-data; boundary=<calculated when request is sent>",
                        // "Content-Type":"application/json",
                    },
                });
            }
            
            // console.log(response.data)
            return response
        }


        try {
            if (props.name == 'Video') {
                let form_data = new FormData();
                form_data.append("asset_title", assetTitle);
                form_data.append("asset_category", props.name);
                form_data.append("asset_url", assetUrl);
                form_data.append("asset_description", assetDesc);
                form_data.append("asset_thumbnail", assetThumbnail);
                form_data.append("video_type", videoPreview);

                // let data = JSON.stringify({
                //     asset_title: assetTitle,
                //     asset_type: props.name,
                //     asset_url: assetUrl,
                //     asset_description: assetDesc,
                //     asset_thumbnail: assetThumbnail,
                //     video_type: videoPreview,

                // })
                let response = await Post(form_data)
                // console.log(response.data)
                if (response?.data?.success == true) {
                    setAssetTitle("");
                    setAssetDesc("");
                    setAssetUrl("");
                    setAssetThumbnail("")
                    setFileValue("")

                    // alert("Asset Uploded Successfully");
                    Swal.fire("", response?.data?.message, 'success')
                    getAssetList()
                } else {
                    Swal.fire("", response?.data?.message, 'error')
                    // console.log(response)
                }
            } else {
                let form_data = new FormData();
                form_data.append("asset_title", assetTitle)
                form_data.append("asset_category", props.name)
                form_data.append("asset_description", assetDesc)
                form_data.append("asset_file", assetFile)
                // let data = JSON.stringify({
                //     asset_title: assetTitle,
                //     asset_type: props.name,
                //     asset_description: assetDesc,
                //     asset_file: assetFile,

                // })
                let response = await Post(form_data)
                // console.log(response);
                // let resJson = await res.json();
                if (response?.data?.success == true) {
                    setAssetTitle("");
                    setAssetDesc("");
                    setAssetFile("");
                    setFileValue("")

                    Swal.fire("", response?.data?.message, 'success')
                    getAssetList()
                } else {
                    Swal.fire("", response?.data?.message, 'error')
                    // console.log(response)
                }
            }
        } catch (err) {
            // console.log(err)
        }
    }
    if (props.name == 'Video') {

        return (
            <>
                <div className={edit ? "col-lg-12" : "col-lg-6"}>
                    <div className="card">
                        <div className="card-header h4">
                            {props.name} Upload
                        </div>
                        <div className="card-body">

                            <div className="row">
                                {video_type.map((data, index) => {
                                    return (
                                        <div key={"video" + index.toString()} className="col">
                                            <button onClick={() => setVideoPreview(data.value)} className="btn btn-primary col-12 rounded-pill">{data.type}</button>
                                        </div>
                                    )
                                })}
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="p-4">
                                    <div className="form-group pt-3">
                                        <label htmlFor="">Title</label>
                                        <input value={assetTitle} onChange={(e) => setAssetTitle(e.target.value)} type="text" className="form-control" name="asset_title" id="asset_title" />
                                    </div>
                                    <div className="form-group pt-3">
                                        <label htmlFor="">Url</label>
                                        <input value={assetUrl} onChange={(e) => UrlChange(e)} type="text" className="form-control" name="video_url" id="video_url" />
                                    </div>
                                    <div className=" pt-3">
                                        <label htmlFor="">Thumbnail</label>
                                        {/* <FileType type={props.name} setAssetFile={setAssetFile}/> */}
                                        <div className="form-file">
                                            <input value={fileValue} onChange={(e) => setAssetThumbnail(handleFile(e))} accept="image/*" type="file" className="form-file-input form-control" />
                                        </div>
                                    </div>
                                    <div className="pt-3">
                                        <label htmlFor="">Description</label>
                                        <textarea value={assetDesc} onChange={(e) => setAssetDesc(e.target.value)} className="form-control" name="asset_desc" id="asset_desc" ></textarea>
                                    </div>
                                    <div className="text-center mt-2">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {!edit && <div className="col-lg-6">
                    <div className="card">
                        <div className="card-header h4">
                            {assetUrl && assetUrl != '' ? "Video Preview" : "Recent Uploads"}
                        </div>
                        <div className="card-body">
                            {assetUrl && assetUrl != '' && <VideoPreview url={assetUrl} type={videoPreview} />}
                            {assetUrl && assetUrl != '' && <div className="card-header h4 mt-4">
                                Recent Uploads
                            </div>}
                            <div className="">
                                <RecentUploads {...props} />
                            </div>
                        </div>

                    </div>
                </div>}
            </>
        )
    }

    // NON VIDEO FILES

    else if (props.name == 'Audio') {
        return (
            <>
                <div className={edit ? "col-lg-12" : "col-lg-6"}>
                    <div className="card ">
                        <div className="card-header h4">
                            {props.name} Upload
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="p-4">
                                    <div className="form-group pt-3">
                                        <label htmlFor="">Title</label>
                                        <input value={assetTitle} onChange={(e) => setAssetTitle(e.target.value)} type="text" className="form-control" name="" id="" />
                                    </div>
                                    <div className="pt-3">
                                        <label htmlFor="">Description</label>
                                        <textarea value={assetDesc} onChange={(e) => setAssetDesc(e.target.value)} className="form-control" name="" id="" ></textarea>
                                    </div>
                                    <div className="input-group pt-3">
                                        {/* <FileType type={props.name} setAssetFile={setAssetFile}/> */}
                                        <div className="form-file">
                                            <input accept="audio/*" onChange={(e) => setAssetFile(handleFile(e))} type="file" className="form-file-input form-control" />
                                        </div>
                                    </div>
                                    <div className="text-center mt-2">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {!edit && <div className="col-lg-6">
                    <div className="card">
                        <div className="card-header h4">
                            Recent Uploads
                        </div>
                        <div className="card-body">
                            <RecentUploads {...props} type={props.name} />
                        </div>
                    </div>
                </div>}
            </>
        )
    } else if (props.name == 'Document') {
        return (
            <>
                <div className={edit ? "col-lg-12" : "col-lg-6"}>
                    <div className="card ">
                        <div className="card-header h4">
                            {props.name} Upload
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="p-4">
                                    <div className="form-group pt-3">
                                        <label htmlFor="">Title</label>
                                        <input value={assetTitle} onChange={(e) => setAssetTitle(e.target.value)} type="text" className="form-control" name="" id="" />
                                    </div>
                                    <div className="pt-3">
                                        <label htmlFor="">Description</label>
                                        <textarea value={assetDesc} onChange={(e) => setAssetDesc(e.target.value)} className="form-control" name="" id="" ></textarea>
                                    </div>
                                    <div className="input-group pt-3">
                                        {/* <FileType type={props.name} setAssetFile={setAssetFile}/> */}
                                        <div className="form-file">
                                            <input accept=".csv,.doc,.xlsx,.pdf" value={fileValue} onChange={(e) => setAssetFile(handleFile(e))} type="file" className="form-file-input form-control" />
                                        </div>
                                    </div>
                                    <div className="text-center mt-2">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {!edit && <div className="col-lg-6">
                    <div className="card">
                        <div className="card-header h4">
                            Recent Uploads
                        </div>
                        <div className="card-body">
                            <RecentUploads {...props} type={props.name} />
                        </div>
                    </div>
                </div>}
            </>
        )
    } else if (props.name == 'Others') {
        return (
            <>
                <div className={edit ? "col-lg-12" : "col-lg-6"}>
                    <div className="card ">
                        <div className="card-header h4">
                            {props.name} Upload
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="p-4">
                                    <div className="form-group pt-3">
                                        <label htmlFor="">Title</label>
                                        <input onChange={(e) => setAssetTitle(e.target.value)} type="text" className="form-control" name="" id="" />
                                    </div>
                                    <div className="pt-3">
                                        <label htmlFor="">Description</label>
                                        <textarea value={assetDesc} onChange={(e) => setAssetDesc(e.target.value)} className="form-control" name="" id="" ></textarea>
                                    </div>
                                    <div className="input-group pt-3">
                                        {/* <FileType type={props.name} setAssetFile={setAssetFile}/> */}
                                        <div className="form-file">
                                            <input accept="" onChange={(e) => setAssetFile(handleFile(e))} type="file" className="form-file-input form-control" />
                                        </div>
                                    </div>
                                    <div className="text-center mt-2">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {!edit && <div className="col-lg-6">
                    <div className="card">
                        <div className="card-header h4">
                            Recent Uploads
                        </div>
                        <div className="card-body">
                            <RecentUploads {...props} type={props.name} />
                        </div>
                    </div>
                </div>}
            </>
        )
    }
}

// function FileType(props) {
//     const setAssetFile = props.setAssetFile;
//     const [accepted,setAccepted] = useState("")
//     // console.log(props)
//     // if(props.type=='Audio'){
//     //     setAccepted('audio/*')
//     // }
//     // else if(props.type=='Document'){
//     //     setAccepted('audio/*')
//     // }
//     // else if(props.type=='Others'){
//     //     setAccepted('')
//     // }
//     return (
//         <div className="form-file">
//             <input onChange={(e) => setAssetFile(e.target.value)} type="file" accept={accepted} className="form-file-input form-control" />
//         </div>
//     )
// }
export default AssetForm;