import "../assets/styles/Banners.css";
import React, { useContext, useEffect, useState } from 'react'
import NavTitleContext from '../context/NavTitleProvider'
import BannerComponent from "../components/banner/BannerComponents";
import Modal from "../components/Model";
import add_icon from '../assets/images/img/add_image_icon.jpg'

const Banners = () => {
  const [imagePreview, setImagePreview] = useState(add_icon)
  const [selected, setSelected] = useState("")
  const { setNavbarText } = useContext(NavTitleContext)
  useEffect(() => {
    setNavbarText("Banners")
  }, [])



  useEffect(() => {
    if (!selected) {
      setImagePreview(add_icon)
      return
    }

    const objectUrl = URL.createObjectURL(selected)
    setImagePreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selected])

  const handleImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelected(undefined)
      return
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelected(e.target.files[0])
    // console.log(event.target)
    // setImagePreview(event.target.value)
  }
  return (
    <div className="content-body pe-0 mt-3">
      <div className="row widget-heading">
        <div className="ms-5 col-9">
          <h2>Banners </h2>
        </div>
        <div className="col-2">
          {/* <Link to="courses.html" className="btn btn-primary btn-md col-12 fs-1">Add</Link> */}
          <button type="button" className="btn btn-primary mb-2 float-end btn-md" data-bs-toggle="modal" data-bs-target="#addNewBannerModal">
            <i className="bi bi-plus"></i> Create
          </button>
          <Modal size="xl" id="addNewBannerModal" header="Add new banner">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group fs-4 mb-2">
                  <label htmlFor="title" className="form-title">Banner title</label>
                  <input id="title" type="text" className="form-control" placeholder="Add your title" />
                </div>
                <div className="form-group fs-4 mb-2">
                  <label htmlFor="subtitle" className="">Subtitle</label>
                  <input type="text" id="subtile" className="form-control" placeholder="Add your subtitle" />
                </div>
                <div className="form-group fs-4 mb-2">
                  <label htmlFor="comments" className="">Comments</label>
                  <input type="text" id="comments" className="form-control" placeholder="Add your comments" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="col-6 mx-auto mt-3 p-3">
                  <label htmlFor="image">
                    <img src={imagePreview} style={{ border: "1px dashed",height:"15rem",width:"20rem" }} className=" mx-auto " alt="" />
                  </label>
                  <input onChange={(e) => handleImage(e)} type="file" accept="image/*" id="image" className="form-control" hidden />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4 mt-5 mx-auto row">
                <div className="col-6">
                <button className=" col-12 btn btn-primary">Submit</button></div>
                <div className="col-6">
                <button className="btn col-12 btn-danger">Cancel</button></div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
      <div className='container-fluid'>
        <div className="row">
          <div className='col-md-6'>
            <BannerComponent title="Education Material" subtitle="Study Materials on offer" description="sdasdasd asdhjsdaskdg asdsajd" />
          </div>
          <div className='col-md-6'>
            <BannerComponent />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banners

