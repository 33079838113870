import "../assets/styles/Courses.css"

import add_content from "../assets/images/icons/add_content.png";
import edit_icon from '../assets/images/icons/edit_icon.png';
import star from "../assets/images/icons/star_icon.png";
import doc from "../assets/images/icons/doc_icon.png";

import { Link } from "react-router-dom";
import { useState, useEffect, Fragment, useContext } from "react";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from "@mui/material/StepContent";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import CourseContent from "../components/CourseContent";
import Swal from "sweetalert2";
import useAuth from "../hooks/useAuth";
import { MEDIA_URL } from "../api/axios";
import NavTitleContext from "../context/NavTitleProvider";

const steps = ['Course Structure', 'Course Details'];


export default function Courses(props) {


	const axiosPrivate = useAxiosPrivate();

	const [courseType, setCourseType] = useState('');

	const [courseName, setCourseName] = useState('');
	const [price, setPrice] = useState('');
	const [purchasePrice, setPurchasePrice] = useState('');
	const [offer, setOffer] = useState('');
	const [courseImage, setCourseImage] = useState('');
	const [resetImage, setResetImage] = useState('')
	const [courseDesc, setCourseDesc] = useState('');
	const [duration, setDuration] = useState('')
	const [message, setMessage] = useState('')

	const [courseList, setCourseList] = useState('')

	const [courseId, setCourseId] = useState(0)
	const [name, setName] = useState('')
	const [contentType, setContentType] = useState(null)
	const [contentList, setContentList] = useState(null);
	const [courseContentList, setCourseContentList] = useState(null)

	const [edit, setEdit] = useState(false);

	const [searchData, setSearchData] = useState('')
	// const [courseContents, setCourseContents] = useState(null)


	const { auth } = useAuth();



	const [activeStep, setActiveStep] = useState(0);
	const [skipped, setSkipped] = useState(new Set());

	const { setNavbarText } = useContext(NavTitleContext)
	useEffect(() => {
		setNavbarText("Courses")
	}, [])



	async function getCourseList(search) {
		let searchdata = searchData ? searchData : ""
		var response;
		if (search || searchData != "") {
			response = await axiosPrivate.get('/course/', {
				params: {
					'keyword': searchdata
				}
			})
		} else {
			response = await axiosPrivate.get('/course/')
		}

		// console.log(response)
		if (response?.data) {
			setCourseList(response?.data?.data)
		}
	};
	const getContent = async (e) => {
		var CONTENT_URL;
		if (contentType) {
			if (contentType === 1) {
				CONTENT_URL = "/topics/"
			}
			else if (contentType === 2) {
				CONTENT_URL = '/chapters/'
			} else if (contentType === 3) {
				CONTENT_URL = '/subjects/'
			} else {
				Swal.fire("", "Invalid content type", 'error')
			}
			if (CONTENT_URL) {
				try {
					let response = await axiosPrivate.get(CONTENT_URL)
					if (response?.data) {
						setContentList(response?.data?.data?.results)
						// console.log(response?.data?.data)
					}
				} catch (err) {
					// console.log(err)
				}
			}
		}
	}

	const getCourseContent = async (e) => {
		if (courseId) {
		let URL = "/course/content/"+courseId+"/";
		// if (contentType === 1) {
		// 	CONTENT_URL = "/topics/"
		// }
		// else if (contentType === 2) {
		// 	CONTENT_URL = '/chapters/'
		// } else if (contentType === 3) {
		// 	CONTENT_URL = '/subjects/'
		// } else {
		// 	Swal.fire("", "Invalid content type", 'error')
		// }
		// if (CONTENT_URL) {
		try {
			let response = await axiosPrivate.get(URL)
			if (response?.data) {
				setCourseContentList(response?.data?.data)
				console.log(response?.data?.data)
			}
		} catch (err) {
			// console.log(err)
		}
		}
		// }
	}

	const getCourseDetails = async () => {
		try {
			let id = courseId && courseId
			let URL = '/course/' + id + '/'
			let response = await axiosPrivate.get(URL)
			if (response?.data?.success) {
				let data = response?.data?.data
				setCourseName(data.course_name)
				setPrice(data.price)
				setPurchasePrice(data.purchase_price)
				setOffer(data.offer)
				setCourseImage(data.course_image)
				setCourseDesc(data.course_description)
				setDuration(data.duration)
				setCourseType(data.course_type)

			}
		} catch (err) {
			// console.log(err)
		}
	}
	useEffect(() => {
		// console.log("Edit tru")
		if (edit) {
			getCourseDetails();
		}
	}, [edit, courseId])

	const handleClear = () => {
		setCourseName('')
		setPrice('')
		setPurchasePrice('')
		setOffer('')
		setCourseImage('')
		setCourseDesc('')
		setDuration('')
		setCourseType('')
		setResetImage('')
		setCourseId('')
	}

	useEffect(() => {
		getContent();
		getCourseContent();
	}, [contentType, name, courseId])

	useEffect(() => {
		getCourseList()
	}, [])

	const isStepOptional = (step) => {
		return step === 1;
	};

	const isStepSkipped = (step) => {
		return skipped.has(step);
	};

	const handleNext = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleSkip = () => {
		if (!isStepOptional(activeStep)) {
			// You probably want to guard against something like this,
			// it should never occur unless someone's actively trying to break something.
			throw new Error("You can't skip a step that isn't optional.");
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped((prevSkipped) => {
			const newSkipped = new Set(prevSkipped.values());
			newSkipped.add(activeStep);
			return newSkipped;
		});
	};

	const handleReset = () => {
		setActiveStep(0);
	};
	const changeActive = async (event, id) => {
		let ACTIVE_URL = "/course/" + id + "/activation_status/change/"

		try {
			let response = await axiosPrivate.put(ACTIVE_URL)
			// console.log(response)
			if (response?.data?.success) {
				getCourseList()
			}
		} catch (err) {
			// console.log(err)
		}
	}


	const handleSubmit = async (e) => {
		e.preventDefault();

		let form_data = new FormData();
		// if (data.image_url)
		form_data.append("course_image", courseImage);
		form_data.append("course_name", courseName);
		form_data.append("price", price);
		form_data.append("purchase_price", purchasePrice);
		form_data.append("offer", offer);
		form_data.append("course_description", courseDesc);
		form_data.append("duration", duration);
		form_data.append("course_type", courseType);

		try {
			var URL;
			var response;
			if (edit) {
				URL = "/course/" + courseId + "/"
				response = await axiosPrivate.put(URL, form_data,
					{
						headers: {
							"Content-Type": "multipart/form-data",
							// "Content-Type":"application/json",
						},
					})
			} else {
				URL = "/course/"
				response = await axiosPrivate.post(URL, form_data,
					{
						headers: {
							"Content-Type": "multipart/form-data",
							// "Content-Type":"application/json",
						},
					})
			}

			if (response?.data?.success === true) {
				setCourseName('')
				setPrice('')
				setPurchasePrice('')
				setOffer('')
				setCourseImage('')
				setCourseDesc('')
				setDuration('')
				setCourseType('')
				setResetImage('')
				// Swal.fire("", response?.data?.message, 'success')
				setMessage(response?.data?.message)
				getCourseList()
				handleNext()

			} else {
				// console.log(res.data)
				Swal.fire("", response?.data?.message, 'error')
			}


		} catch (err) {
			// console.log(err)
		}
	}
	const handleNew = () => {
		setEdit(false);
		setActiveStep(0);
		handleClear();
	}

	const handleSearch = () => {
		getCourseList(true)
	}


	return (
		<div className="content-body pe-0">
			<div className="container-fluid">
				{/* <div className="widget-heading d-flex justify-content-between align-items-center"> */}
				<div className="row widget-heading ">
					<div className="col-10">
						<div className="input-group search-area">

							<input value={searchData} onChange={(e) => setSearchData(e.target.value)} type="text" className="form-control" placeholder="Search here..." />
							<span className="input-group-text bg-primary">
								<button onClick={handleSearch} className="btn p-0">
									<svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z" fill="var(--secondary)" />
									</svg>
								</button></span>
						</div></div>
					<div className="col-2">
						{/* <Link to="courses.html" className="btn btn-primary btn-md col-12 fs-1">Add</Link> */}
						<button type="button" onClick={handleNew} style={{ borderRadius: "1rem" }} className="btn btn-primary mb-2 float-end btn-md col-10" data-bs-toggle="modal" data-bs-target="#addNewModal">
							Add New
						</button>

						{/* Course create model  */}

						<div className="modal fade" id="addNewModal">
							<div className="modal-dialog modal-xl modal-dialog-centered" role="document">
								<div className="modal-content">
									<div className="modal-header">
										<h3 className="modal-title">Create Course</h3>
										<button type="button" className="btn-close" data-bs-dismiss="modal">
										</button>
									</div>
									<div className="modal-body">
										<form onSubmit={handleSubmit} >
											<Box sx={{ width: '100%' }}>
												{!edit && <Stepper activeStep={activeStep}>
													{steps.map((label, index) => {
														const stepProps = {};
														const labelProps = {};
														// if (isStepOptional(index)) {
														// 	labelProps.optional = (
														// 		<Typography variant="caption">Optional</Typography>
														// 	);
														// }
														if (isStepSkipped(index)) {
															stepProps.completed = false;
														}
														return (
															<Step key={label} {...stepProps}>
																<StepLabel {...labelProps}>{label}</StepLabel>
																{/* <StepContent>asdasd</StepContent> */}

															</Step>
														);
													})}
												</Stepper>}
												{activeStep === steps.length ? (
													<Fragment>
														<Typography sx={{ mt: 20, mb: 10, variant: "h4", align: "center" }}>
															{message}
														</Typography>

														{/* {activeStep === 2 && (<CourseContent />)} */}
														<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
															<Box sx={{ flex: '1 1 auto' }} />
															<Button data-bs-dismiss="modal" data-bs-target="#addNewModal" onClick={() => setTimeout(handleReset, 500)}>Close</Button>
														</Box>
													</Fragment>
												) : (
													<Fragment>
														{/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
														{activeStep === 0 && (<CourseStructure courseType={courseType} setCourseType={setCourseType} />)}
														{activeStep === 1 && (<CourseDetails state={[courseName, price, purchasePrice, offer, courseImage, resetImage, courseDesc, duration]} setState={[setCourseName, setPrice, setPurchasePrice, setOffer, setCourseImage, setResetImage, setCourseDesc, setDuration]} />)}
														<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
															{!edit && <Button
																color="inherit"
																disabled={activeStep === 0}
																onClick={handleBack}
																sx={{ mr: 1 }}
															>
																Back
															</Button>}
															<Box sx={{ flex: '1 1 auto' }} />
															{/* {isStepOptional(activeStep) && (
															<Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
																Skip
															</Button>
														)} */}
															{
																activeStep === steps.length - 1 && (
																	<Button type="submit">
																		Submit
																		{/* {activeStep === steps.length - 2 ? 'Submit' : 'Next'} */}
																	</Button>
																)}{
																activeStep != steps.length - 1 && (
																	<Button type="button" onClick={handleNext}>
																		Next
																		{/* {activeStep === steps.length - 2 ? 'Submit' : 'Next'} */}
																	</Button>
																)
															}
														</Box>
													</Fragment>
												)}
											</Box>
										</form>
									</div>

								</div>
							</div>
						</div>

						{/* Course create modal end */}


					</div>
				</div>
				{/* </div> */}
				<div className="row">
					<div className="swiper course-slider">
						<div className="swiper-wrapper">

							<div className="row">
								{courseList && courseList.map((props, index) => <div key={index.toString()} className="col-lg-4 col-md-6 col-sm-12p-"><Course {...props} setName={setName} setEdit={setEdit} setActiveStep={setActiveStep} changeActive={changeActive} setContentType={setContentType} setCourseId={setCourseId} /></div>)}
							</div>
							{/* Course upload model start */}

							<div className="modal fade" id="uploadCourseModal">
								<div className="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div className="modal-content">
										<div className="modal-header">
											<h3 className="modal-title">Course Contents</h3>
											<button type="button" className="btn-close" data-bs-dismiss="modal">
											</button>
										</div>
										<div className="">
											<CourseContent getCourseContent={getCourseContent} courseContentList={courseContentList} contentList={contentList} header={name} courseId={courseId} />
										</div>
									</div>
								</div>
							</div>

							{/* Course upload model end */}


						</div>
					</div>
				</div>
			</div>

		</div>
	)
}

const CourseStructure = (props) => {
	return (
		<div className=" container overflow-auto" style={{ height: "350px" }}>
			<div className="row d-flex justify-content-evenly">
				<div className="col-3 m-2">
					<div className="img">
						<img src="" height="250" width="100%" alt="" />
					</div>
					<div className="d-flex justify-content-center mt-3">
						<input onChange={(e) => props.setCourseType(e.target.value)} type="radio" name="setstructure" value="3" checked={props.courseType === "3"} />CSCT
					</div>
				</div>
				<div className="col-3 m-2">
					<div className="img">
						<img src="" height="250" width="100%" alt="" />
					</div>
					<div className="d-flex justify-content-center mt-3">
						<input onChange={(e) => props.setCourseType(e.target.value)} type="radio" name="setstructure" value="2" checked={props.courseType === "2"} />CCT
					</div>
				</div>
				<div className="col-3 m-2">
					<div className="img">
						<img src="" height="250" width="100%" alt="" />
					</div>
					<div className="d-flex justify-content-center mt-3">
						<input onChange={(e) => props.setCourseType(e.target.value)} type="radio" name="setstructure" value="1" checked={props.courseType === "1"} />CT
					</div>
				</div>

			</div>
		</div>
	)
}
const CourseDetails = ({ state, setState }) => {
	const [courseName, price, purchasePrice, offer, courseImage, resetImage, courseDesc, duration] = state;
	const [setCourseName, setPrice, setPurchasePrice, setOffer, setCourseImage, setResetImage, setCourseDesc, setDuration] = setState;
	const [image, setImage] = useState('')

	function handleFile(event) {
		let img = event.target.files[0]
		// console.log(event)
		setCourseImage(img)
		setResetImage(event.target.value)
	}
	return (
		<div className="container overflow-auto " style={{ height: "350px" }}>
			<div className="row">
				<div className="col-12 my-2">
					<label htmlFor="coursename">Course Name</label>
					<div className="">
						<input id="coursename" value={courseName} onChange={(e) => setCourseName(e.target.value)} className="form-control" type="text" />
					</div>
				</div>
				<div className="col-12 col-md-6 my-2">
					<label htmlFor="price">Price</label>
					<div className="">
						<input id="price" value={price} onChange={(e) => setPrice(e.target.value)} className="form-control" type="text" />
					</div>
				</div>
				<div className="col-12 col-md-6 my-2">
					<label htmlFor="offer">Offer</label>
					<div className="">
						<input id="offer" value={offer} onChange={(e) => setOffer(e.target.value)} className="form-control" type="text" />
					</div>
				</div>
				<div className="col-12 col-md-6 my-2">
					<label htmlFor="purchaseprice">Purchase price</label>
					<div className="">
						<input id="purchaseprice" value={purchasePrice} onChange={(e) => setPurchasePrice(e.target.value)} className="form-control" type="text" />
					</div>
				</div>
				<div className="col-12 my-2">
					<label htmlFor="thumbnail">Course Thumbnail</label>
					<div className="form-file">
						<input id="thumbnail" accept="image/*" value={resetImage} onChange={(e) => handleFile(e)} type="file" className="form-file-input form-control" />
					</div>
				</div>
				<div className="col-12 my-2">
					<label htmlFor="desc">Course Description</label>
					<div className="">
						<textarea name="" value={courseDesc} onChange={(e) => setCourseDesc(e.target.value)} className="form-control" id="desc" cols="30" rows="10"></textarea>
					</div>
				</div>
				<div className="col-12 col-md-6 my-2">
					<label htmlFor="duration">Course Duration</label>
					<div className="">
						<input id="duration" value={duration} onChange={(e) => setDuration(e.target.value)} className="form-control" type="text" />
					</div>
				</div>

			</div>
		</div>
	)
}

const Course = (props) => {
	const { setCourseId, setContentType, setName, setEdit } = props;
	const { changeActive } = props
	const { setActiveStep } = props
	const image = MEDIA_URL + props.course_image

	const addContent = async (event, id, name, type) => {
		setCourseId(id)
		setName(name);
		setContentType(type)
	}
	const handleEdit = (id) => {
		setCourseId(id)
		// console.log("Edit active")
		setEdit(true)
		setActiveStep(1);
	}
	return (
		// <div className="swiper-slide">
		<div className="position-relative group-93-hm1">
			<div className="">
				<div className="">
					<div className="">
						<img className="card-header-img" style={{ width: "100%", height: "155px" }} src={image} alt="" />
					</div>
					<div className="" style={{ position: "absolute", right: 0, top: 0 }}>
						<button data-bs-toggle="modal" data-bs-target="#addNewModal" onClick={() => handleEdit(props.id)} className="btn btn-sm text-success mt-1">
							<img src={edit_icon} alt="" />
							
						</button>
					</div>
				</div>

			</div>
			<div className=" pt-1 m-0">

				<div className="row mt-2">
					<div className="col-7">
						<div className="">
							<h4 className="">{props.course_name}</h4>
						</div>
						<div className="" style={{ height: "50px", overflow: "hidden" }}>
							{props.course_description}
						</div>
						<div className="row">
							<div className="col-8 row">
								<div className="col-1">
									<img src={doc} alt="" />
								</div>
								{/* <div className="col-8"> */}
								{props.lessons ? props.lessons : "0"} Lessons
								{/* </div> */}
							</div>
							<div className="col-6 row">
								<div className="col-5">
									<img src={star} alt="" />
								</div>
								{/* <div className="col-"> */}
								{props.rating + "(" + props.rating_count + ")"}
								{/* </div> */}

							</div>
						</div>
					</div>
					<div className="col-5 ">
						<div className="mb-2 ">
							<button
								// style={{backgroundImage:`url(${add_content})`,backgroundSize:"100%",backgroundRepeat: "no-repeat",backgroundPosition: "center",width:"6rem",height:"5rem"}}
								data-bs-toggle="modal"
								data-bs-target="#uploadCourseModal"
								onClick={(e) => addContent(e, props.id, props.course_name, props.course_type)}
								className="btn d-flex  col-12 p-0"
								style={{ justifyContent: 'center' }}
							>
								<img className="col-12 m-0" src={add_content} alt="" />
							</button>
						</div>
						<div className="">
							<button
								onClick={(e) => changeActive(e, props.id)}
								className={props.is_active ? "btn btn-sm btn-outline-danger col-12 " : "btn btn-sm btn-outline-primary col-12"} >
								{props.is_active ? "Un-publish" : "Publish"}
							</button>
						</div>
					</div>
				</div>

				{/* <div className="row">
					<h4>₹{props.purchase_price}</h4>
				</div> */}
				{/* <div className="col-2">
						<h4>CTA</h4>
					</div> */}
				<div className="row">
					{/* <div className="mb-2">
						<button data-bs-toggle="modal" data-bs-target="#uploadCourseModal" onClick={(e) => addContent(e, props.id, props.course_name, props.course_type)} className="btn btn-sm btn-outline-info col-12 rounded-pill">Add content</button>
					</div> */}
					{/* <div className="">
						<button onClick={(e) => changeActive(e, props.id)} className={props.is_active ? "btn btn-sm btn-outline-danger col-12 rounded-pill" : "btn btn-sm btn-outline-success col-12 rounded-pill"} >{props.is_active ? "Un-publish" : "Publish"}</button>
					</div> */}
				</div>

				{/* </div> */}
				{/* <Link	 ><i className="las la-angle-right text-primary"></i></Link> */}
				{/* </div> */}
			</div>
		</div>
		// </div>
	)
}
// const CourseContent = (props) => {
// 	return (
// 		<div className="">Yahalo</div>
// 	)
// }