import { Link, useLocation } from "react-router-dom"
import useAuth from "../hooks/useAuth";

function Sidebar() {
    const { auth, setAuth } = useAuth();

    const location = useLocation()

    // const[navtitle,setNavtitle] = useState("Navbar")

    const logout = () => {
        setAuth('')
        localStorage.setItem("userName", "")
        localStorage.setItem("passWord", "")
        localStorage.setItem("accessToken", "")
        localStorage.setItem("role", "")
        localStorage.setItem("institute", "")
    }
    return (
        auth?.role == "company" ?
            <>
                <div className="dlabnav">
                    <div className="dlabnav-scroll">
                        <ul className="metismenu" id="menu">
                            
                            <li className={location.pathname == "/" ? "mm-active" : ""}>
                                <Link className="py-2 " to="/" aria-expanded="false">
                                    <i className="bi bi-grid"></i>
                                    <span className="nav-text">Dashboard</span>
                                </Link>
                            </li>
                            <li className={location.pathname == "institutelist" ? "mm-active" : ""}><Link to="/institutelist" className="has-arrow" aria-expanded="false">
                                <i className="bi bi-grid"></i>
                                <span className="nav-text">Institute List</span>
                            </Link>

                            </li>
                        </ul>

                        {/* <SidebarFooter /> */}
                    </div>
                </div>
            </> :
            <>
                <div className="dlabnav">
                    <div className="dlabnav-scroll">
                        <ul className="metismenu" id="menu">
                            <li className={location.pathname == "/" ? "mm-active" : ""}>
                                <Link className="py-2" to="/" aria-expanded="false">
                                    <i className="bi bi-grid"></i>
                                    <span className="nav-text">Dashboard</span>
                                </Link>

                            </li>
                            <li className={location.pathname == "/courses" ? "mm-active" : ""}>
                                <Link className="py-2 " to="/courses" aria-expanded="false">
                                    <i className="bi bi-book"></i>

                                    <span className="nav-text">Courses</span>
                                </Link>
                            </li>
                            <li className={location.pathname == "/components" ? "mm-active" : ""}>
                                <Link className="py-2" to="/components" aria-expanded="false">
                                    {/* <i className="bi bi-book"></i> */}
                                    <i className="bi bi-hdd-stack"></i>
                                    <span className="nav-text">Components</span>
                                </Link>
                            </li>
                            <li className={location.pathname == "/assets" ? "mm-active" : ""}>
                                <Link className="py-2 " to="/assets" aria-expanded="false">
                                    <i className="bi bi-bookshelf"></i>
                                    <span className="nav-text">Assets</span>
                                </Link>
                            </li>
                            <li className={location.pathname == "/featured" ? "mm-active" : ""}>
                                <Link className="py-2" to="/featured" aria-expanded="false">
                                    {/* <i className="bi bi-book"></i> */}
                                    <i className="bi bi-hdd-stack"></i>
                                    <span className="nav-text">Featured Courses</span>
                                </Link>
                            </li>
                            <li className={location.pathname == "/banners" ? "mm-active" : ""}>
                                <Link className="py-2" to="/banners" aria-expanded="false">
                                    {/* <i className="bi bi-book"></i> */}
                                    <i className="bi bi-hdd-stack"></i>
                                    <span className="nav-text">Banners</span>
                                </Link>
                            </li>
                            <li className={location.pathname == "/userlist" ? "mm-active" : ""}>
                                <Link className="py-2 " to="/userlist" aria-expanded="false">
                                    <i className="bi bi-people"></i>
                                    <span className="nav-text">Users</span>
                                </Link>
                            </li>
                            <li>
                                <Link className="py-2 btn btn-outline-danger text-start " onClick={logout} aria-expanded="false">
                                    <i className="bi bi-box-arrow-right"></i>
                                    <span className="nav-text">Logout</span>
                                </Link>

                            </li>

                            </ul>
                            {/* <div className="plus-box">
                                <div className="d-flex align-items-center">
                                    <h5>Upgrade your Account to Pro</h5>
                                    <img src="images/medal.png" alt="" />
                                </div>
                                <p>Upgrade to premium to get premium features</p>
                                <Link to="#" className="btn btn-primary btn-sm">Upgrade</Link>
                            </div>
                            <div className="copyright">
                                <p><strong>GetSkills Online Learning Admin</strong> © 2022 All Rights Reserved</p>
                                <p className="fs-12">Made with <span className="heart"></span> by HugeBinarys</p>
                            </div> */}
                            {/* <SidebarFooter /> */}
                    </div>
                </div>
            </>
    )
}
export default Sidebar;

const SidebarFooter = () => {
    return (
        <>
            <div className="plus-box">
                <div className="d-flex align-items-center">
                    <h5>Upgrade your Account to Pro</h5>
                    <img src="images/medal.png" alt="" />
                </div>
                <p>Upgrade to premium to get premium features</p>
                <Link to="#" className="btn btn-primary btn-sm">Upgrade</Link>
            </div>
            <div className="copyright">
                <p><strong>Foxa LMS Admin</strong> © 2022 All Rights Reserved</p>
                <p className="fs-12">Made with <span className="heart"></span> by HugeBinarys</p>
            </div></>
    )
}