import { useEffect } from "react";
import { useState } from "react";

const Modal = (props) => {
    // const [size,setSize]=useState("modal-md")
    // useEffect(()=>{
        
    // props.size&&setSize("modal-"+props.size)
    // },[])
    return (
        <div className="modal fade" id={props.id}>
            <div className={"modal-dialog modal-"+ props.size +" modal-dialog-centered"} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title">{props.header}</h3>
                        <button type="button" className="btn-close" data-bs-toggle="modal" data-bs-target={"#"+props.id}>
                        </button>
                    </div>
                    <div className="modal-body">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
                )
}
export default Modal;