import Package from "../components/Package"
import { Link } from "react-router-dom"

export default function Packages(){
    const courses=[
        {id:1,name:'Fullstack Developer',trainer:'Samantha William',price:50.99,rating:5.0,content:110},
        {id:1,name:'UI Design Beginner',trainer:'Karen Hope',price:50.99,rating:'',content:110},
        {id:1,name:'How to be Freelancer',trainer:'Jack and Sally',price:59.99,rating:4.5,content:110},
        {id:1,name:'UX Research',trainer:'Cahaya Hikari',price:40.99,rating:4.7,content:110},
        {id:1,name:'Basic Web Design',trainer:'Johnny Ahmad',price:49.99,rating:5.0,content:110},
        {id:1,name:'3D Character Design',trainer:'Jordan Nico',price:48.99,rating:5.0,content:110},
        
    ]
    return(
        <div className="content-body pe-0">
        <div class="container-fluid">
				<div class="widget-heading d-flex justify-content-between align-items-center">
					<h3 class="m-0">Popular This Week</h3>
					<Link to="courses.html" class="btn btn-primary btn-sm">View all</Link>
				</div>
				<div class="row">
					<div class="swiper course-slider">
					  <div class="swiper-wrapper">
						<div class="swiper-slide">
							<div class="card">
								<div class="card-body">
									<div class="widget-courses align-items-center d-flex justify-content-between flex-wrap">
										<div class="d-flex align-items-center flex-wrap">
											<img src="images/svg/color-palette.svg" alt=""/>
											<div class="flex-1 ms-3">
												<h4>Graphic</h4>
												<span>Lorem ipsum dolor</span>
											</div>	
										</div>	
										<Link ><i class="las la-angle-right text-primary"></i></Link>
									</div>
								</div>
							</div>
						</div>
						<div class="swiper-slide">
							<div class="card">
								<div class="card-body">
									<div class="widget-courses align-items-center d-flex justify-content-between flex-wrap">
										<div class="d-flex align-items-center flex-wrap">
											<img src="images/svg/education-website.svg" alt=""/>
											<div class="flex-1 ms-3">
												<h4>Coading</h4>
												<span>Lorem ipsum dolor</span>
											</div>	
										</div>	
										<Link ><i class="las la-angle-right text-primary"></i></Link>
									</div>
								</div>
							</div>
						</div>
						<div class="swiper-slide">
							<div class="card">
								<div class="card-body">
									<div class="widget-courses align-items-center d-flex justify-content-between flex-wrap">
										<div class="d-flex align-items-center flex-wrap">
											<img src="images/svg/brain.svg" alt=""/>
											<div class="flex-1 ms-3">
												<h4>Soft Skill</h4>
												<span>Lorem ipsum dolor</span>
											</div>	
										</div>	
										<Link ><i class="las la-angle-right text-primary"></i></Link>
									</div>
								</div>
							</div>
						</div>
						<div class="swiper-slide">
							<div class="card">
								<div class="card-body">
									<div class="widget-courses align-items-center d-flex justify-content-between flex-wrap">
										<div class="d-flex align-items-center flex-wrap">
											<img src="images/svg/microscope.svg" alt=""/>
											<div class="flex-1 ms-3">
												<h4>Science</h4>
												<span>Lorem ipsum dolor</span>
											</div>	
										</div>	
										<Link ><i class="las la-angle-right text-primary"></i></Link>
									</div>
								</div>
							</div>
						</div>
					  </div>
					</div>
				</div>	
				<div class="widget-heading d-flex justify-content-between align-items-center">
					<h3 class="m-0">All Courses</h3>
					<Link to="courses.html" class="btn btn-primary btn-sm">View all</Link>
				</div>
                
				<div class="row">
                {courses.map((data)=><Package key={data.id} id={data.id} name={data.name} trainer={data.trainer} rating={data.rating} price={data.price} content={data.content}/>)}
                </div>
        </div>
        </div>
    )
}