import '../assets/styles/Home.css'
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

function Home(props){
    return(
        <>
    {/* <div id="preloader">
		<div className="lds-ripple">
			<div></div>
			<div></div>
		</div>
    </div> */}
    <div id="main-wrapper" className='show'>
		<Navbar />
        <Sidebar/>
        <Outlet></Outlet>
        <Footer/>

	</div>
        </>
    )
}
export default Home;