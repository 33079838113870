import { createContext, useEffect, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    
    // useEffect(() => {
    //     const accessToken = localStorage.getItem("accessToken");
    //     const userName = localStorage.getItem("userName");
    //     const passWord = localStorage.getItem("passWord");
    //     const role = localStorage.getItem("role");
    //     const institute = localStorage.getItem("institute");
    //     console.log({ userName, passWord, accessToken, role, institute })
    //     if (accessToken && role) {
            
    //         setAuth({ userName, passWord, accessToken, role, institute });
    //         console.log("if works")
    //     };
    //     console.log(auth)
    // },[])
        const accessToken = localStorage.getItem("accessToken");
        const userName = localStorage.getItem("userName");
        const passWord = localStorage.getItem("passWord");
        const role = localStorage.getItem("role");
        const institute = localStorage.getItem("institute");
        // console.log({ userName, passWord, accessToken, role, institute })
        if (accessToken && role && !auth.accessToken) {
            
            setAuth({ userName, passWord, accessToken, role, institute });
            // console.log("if works")
        };

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;